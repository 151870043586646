import React, { useContext, useState } from 'react';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { API, COGNITO_POOL_ID } from '../../Constants';
import ScrollToFirstError from '../../route/ScrollToFirstError';
import AddressForm from '../AddressForm';
import BSButton from '../../form/BSButton';
import { formatDate } from '../../Utils';

const UpdateAddress = () => {
    const {
        setClientId,
        setLink2FeedClientId,
        getUserAttributes,
        clientDetails,
        email,
        phone,
        setIsJustUpdatedAddress,
    } = useContext(AccountContext);
    const { dataTypes } = useContext(DataTypeContext);
    const enabledFields = dataTypes.enabledFields || [];
    const history = useHistory();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [sessionExpired, setSessionExpired] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const initialValues = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
    };

    const schema = Yup.object({
        addressLine1: Yup.string()
            .label('Address Line 1')
            .when('noFixedAddress', { is: false, then: Yup.string().required() }),
        ...(enabledFields.includes('addressLine2') && { addressLine2: Yup.string() }),
        city: Yup.string().label('City').when('noFixedAddress', { is: false, then: Yup.string().required() }),
        ...(enabledFields.includes('county') && {
            county: Yup.string().label('County').when('noFixedAddress', { is: false, then: Yup.string().required() }),
        }),
        state: Yup.string()
            .label(t('address.state'))
            .when('noFixedAddress', { is: false, then: Yup.string().required() }),
        zip: Yup.string()
            .label(t('address.zipcode'))
            .when('noFixedAddress', { is: false, then: Yup.string().required() }),
    });

    const sendDataToAPI = async (data) => {
        if (!isLoading) {
            setLoading(true);
            const token = await executeRecaptcha('details');

            let payload = {
                ...clientDetails,
            };

            Object.keys(payload).forEach((key) => {
                if (!enabledFields.includes(key)) {
                    delete payload[key];
                }
            });
            const today = formatDate(new Date());

            payload = {
                ...payload,
                token,
                firstName: clientDetails.firstName,
                lastName: clientDetails.lastName,
                dateOfBirth: clientDetails.dateOfBirth,
                addressLine1: data.addressLine1,
                ...(enabledFields.includes('addressLine2') && { addressLine2: data.addressLine2 || '' }),
                city: data.city,
                ...(enabledFields.includes('county') && { county: data.county }),
                state: data.state,
                zipCode: data.zip,
                householdMembers: data.householdMembers || [],
                email: email,
                phoneNumber: phone,
                noFixedAddress: false,
                shareConsentDate: today,
                textConsentDate: today,
                emailConsentDate: today,
            };

            await fetch(API.NEW_CLIENT_URL, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) =>
                    response.json().then(async (data) => {
                        if (response.ok && data.link2feedId !== undefined) {
                            setClientId(data.link2feedId);
                            setLink2FeedClientId((data.link2feedId || '').toString());

                            const attributes = await getUserAttributes();

                            const body = {
                                id: attributes.sub,
                                pool_id: COGNITO_POOL_ID,
                            };

                            const url = new URL(API.CNCT_ADMIN_REFETCH_USER_URL);
                            url.search = new URLSearchParams(body).toString();

                            fetch(url, { mode: 'no-cors' });

                            setIsJustUpdatedAddress(true);
                            history.push('/book-delivery');
                        } else if (data.message !== undefined || data.error !== undefined) {
                            // if session expired, enable partial profile to confirm DOB and get another session
                            const dataError = data.message || data.error;
                            if (dataError === t('session.apiSessionNotFound')) {
                                setSessionExpired(true);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            } else alert(dataError);
                            setLoading(false);
                        } else {
                            alert('client not created');
                        }
                    })
                )
                .catch((error) => {
                    alert('/clients exception');
                });
        }
    };
    return (
        <Formik
            validationSchema={schema}
            onSubmit={sendDataToAPI}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <Row>
                    <Col md={6} sm={12} className="mx-auto">
                        <h4 className="text-center mb-3"> {t('updateAddress.heading')}</h4>
                        {sessionExpired && <Alert variant="danger">{t('session.sessionExpiredGeneralNotice')}</Alert>}
                        <Form noValidate onSubmit={handleSubmit}>
                            <AddressForm
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                schema={schema}
                                hideUndisclosedCheckbox
                            />
                            <Form.Group className="text-center">
                                <BSButton as={Link} to="/dashboard" variant="secondary" className="mr-3">
                                    {t('core.action.cancel')}
                                </BSButton>
                                <BSButton variant="primary" type="submit" disabled={isLoading}>
                                    {t(`core.action.${isLoading ? 'loading' : 'update'}`)}
                                </BSButton>
                            </Form.Group>
                            <ScrollToFirstError />
                        </Form>
                    </Col>
                </Row>
            )}
        </Formik>
    );
};

export default UpdateAddress;
