import React, { useContext, useCallback, useEffect } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';

import { AccountContext } from '../Accounts';
import { DataTypeContext } from '../DataTypes';
import BSButton from '../form/BSButton';
import { API } from '../Constants';

const ThankYou = () => {
    const { t } = useTranslation();
    const { clientId, username, updateFirstAndLastName } = useContext(AccountContext);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const hasAppointment = dataTypes.agencies.length > 0;
  
    const location = useLocation();
    const isLoginRedirect = location && location.state !== undefined && location.state.isLoginRedirect && location.state.isLoginRedirect === true;
    const { executeRecaptcha } = useGoogleReCaptcha();

    const updateClientName = useCallback(async () => {
        const token = await executeRecaptcha('details');
        const res = await fetch(`${API.CLIENT_DETAILS_URL}/${clientId}?token=${token}`);
        const clientData = await res.json();

        if (clientData && clientData.clientProfileId)
            updateFirstAndLastName(clientData.firstName, clientData.lastName);
    }, [clientId, executeRecaptcha, updateFirstAndLastName]);

    useEffect(() => {
        if (isLoginRedirect) updateClientName();
    }, [isLoginRedirect, updateClientName]);

    return (
        <Row>
            <Col md={6} sm={12} className="mx-auto">
                <br />
                <Alert variant="success">
                    <Alert.Heading
                        dangerouslySetInnerHTML={{
                            __html: dataTypes.thankYouMessage
                                ? dataTypes.thankYouMessage
                                : 'Thank you for registering with foodbank',
                        }}
                    ></Alert.Heading>
                    <hr />
                    <p>
                        Your Login ID is <b>{username}</b>
                    </p>

                    <p>
                        Your Client ID is <b>{clientId}</b>
                    </p>
                    <p>Please provide this when you visit.</p>
                    {hasAppointment && isDeliveryEnabled ? (
                        <Row className="justify-content-center">
                            <Col sm={5} md={6} lg={5} className="mb-3">
                                <BSButton as={Link} to="/book-delivery" variant="primary" className="d-block">
                                    {t('dashboard.bookDelivery')}
                                </BSButton>
                            </Col>
                            {<Col sm={5} md={6} lg={5} className="mb-3">
                                <BSButton as={Link} to="/book-appointment" variant="primary" className="d-block">
                                    {t('dashboard.bookPickup')}
                                </BSButton>
                            </Col>}
                        </Row>
                    ) : hasAppointment && (
                        <p className="text-center">
                            <BSButton as={Link} to="/book-appointment" variant="primary">
                                {t('dashboard.bookServices')}
                            </BSButton>
                        </p>
                    )}
                </Alert>
                <p className="text-center">
                    <Link to="/dashboard">{t('dashboard.return')}</Link>
                </p>
            </Col>
        </Row>
    );
};

export default ThankYou;
