import React, { useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { useFormikContext } from 'formik';
import { stateNames, provinceNames } from '../../Utils';
import { SMARTY_STREETS_KEY, COUNTRY } from '../../Constants';

const SmartyStreetsAutocomplete = (props) => {
    const { setFieldValue, errors } = useFormikContext();
    const [value, setValue] = useState(props.inputProps.value || '');
    const [suggestions, setSuggestions] = useState([]);

    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const smartyStreetsSharedCredentials = new SmartyStreetsCore.SharedCredentials(SMARTY_STREETS_KEY);
    const autoCompleteClientBuilder = new SmartyStreetsCore.ClientBuilder(smartyStreetsSharedCredentials);
    const streetClientBuilder = new SmartyStreetsCore.ClientBuilder(smartyStreetsSharedCredentials);

    const autoCompleteClient = COUNTRY === 'USA' ? autoCompleteClientBuilder.buildUsAutocompleteProClient() : autoCompleteClientBuilder.buildInternationalAddressAutocompleteClient();
    const streetClient = COUNTRY === 'USA' ? streetClientBuilder.buildUsStreetApiClient() : streetClientBuilder.buildInternationalStreetClient();

    function getSuggestions(value) {
        const lookup = new (COUNTRY === 'USA' ? SmartyStreetsSDK.usAutocompletePro.Lookup : SmartyStreetsSDK.internationalAddressAutocomplete.Lookup)(value, COUNTRY);

        autoCompleteClient
            .send(lookup)
            .then((response) => {
                setSuggestions(response.result);
            })
            .catch(console.warn);
    }

    const findAndSetCounty = (suggestion) => {
        const { streetLine, city, state } = suggestionToFields(suggestion);

        const lookup = new (COUNTRY === 'USA' ? SmartyStreetsSDK.usStreet.Lookup : SmartyStreetsSDK.internationalStreet.Lookup)(COUNTRY);

        if (COUNTRY === 'USA') {
            lookup.street = streetLine;
            lookup.city = city;
            lookup.state = state;
        } else {
            lookup.address1 = streetLine;
            lookup.locality = city;
            lookup.administrativeArea = state;
        }
        
        streetClient
            .send(lookup)
            .then((response) => {
                const candidates = response && response.lookups && response.lookups[0] && response.lookups[0].result;
                if (candidates && candidates.length > 0)
                    if (candidates[0] && candidates[0].metadata.countyName)
                        setFieldValue('county', candidates[0].metadata.countyName);
            })
            .catch(console.warn);
    };

    const theme = {
        container: 'autosuggest',
        input: `${!!errors.addressLine1 ? 'form-control is-invalid' : 'form-control'}`,
        suggestionsContainer: 'dropdown',
        suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
        suggestion: 'dropdown-item text-truncate',
        suggestionHighlighted: 'active',
    };

    const suggestionToFields = (suggestion) => {
        let streetLine, city, state, zipcode;

        if (COUNTRY === 'USA') {
            ({ streetLine, city, state, zipcode } = suggestion);
        } else {
            ({ street: streetLine, locality: city, administrativeArea: state, postalCode: zipcode } = suggestion);
        }

        return { streetLine, city, state, zipcode };
    }

    return (
        <>
            <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    getSuggestions(value);
                }}
                onSuggestionSelected={(_, { suggestion }) => {
                    const { streetLine, city, state, zipcode } = suggestionToFields(suggestion);

                    setValue(streetLine);
                    setFieldValue('addressLine1', streetLine);
                    setFieldValue('city', city);
                    if (COUNTRY === 'USA') {
                        setFieldValue('state', stateNames[state]);
                    } else {
                        setFieldValue('state', provinceNames[state]);
                    }

                    if (zipcode) setFieldValue('zip', zipcode);

                    if (COUNTRY === 'USA') {
                        findAndSetCounty(suggestion);
                    }
                }}
                getSuggestionValue={(suggestion) => suggestion.streetLine}
                renderSuggestion={(suggestion) => {
                    const { streetLine, city, state } = suggestionToFields(suggestion);
                    const text = [streetLine, city, state].filter(Boolean).join(', ');

                    return <span title={text}>{text}</span>;
                }}
                inputProps={{
                    ...props.inputProps,
                    value: value,
                    onChange: (_, { newValue, _method }) => {
                        setValue(newValue);
                        setFieldValue('addressLine1', newValue);
                    },
                }}
                theme={theme}
            />
        </>
    );
};

export default SmartyStreetsAutocomplete;
