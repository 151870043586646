import React from "react";
import { useField, useFormikContext } from "formik";
import { Form } from 'react-bootstrap';

const SelectField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const { options, includeEmpty, labelField = 'label', valueField = 'name', ...otherProps } = props;
    const optionsClone = typeof options === 'object' ? Object.values(options) : [...options];
    const defaultOnChange = (e) => {
      setFieldValue(field.name, e.target.value);
    };
    const onChange = props.onChange || defaultOnChange;
    
    if (includeEmpty === true) {
        if (typeof optionsClone[0] === 'object' && optionsClone[0].name !== '')
            optionsClone.unshift({[labelField]: '', [valueField]: ''});
        else if (typeof optionsClone[0] !== 'object' && optionsClone[0] !== '')
            optionsClone.unshift('');
    }

  return (
    <Form.Control
      {...field}
      {...otherProps}
      onChange={onChange}
    >
      {
          optionsClone.map((o) => {
              const isObject = typeof o === 'object';
              const value = isObject ? o[valueField] : o;
              const label = isObject ? o[labelField] : o;
              const key = isObject ? `${field.name}-${value}` : `${field.name}-${o}`;
              return <option value={value} key={key}>{label}</option>;
          })
      }
    </Form.Control>
  );
};

export default SelectField;