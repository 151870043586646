import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { ROLLBAR_TOKEN, ROLLBAR_ENV } from './Constants';

function Rollbar(props) {
  const rollbarConfig = {
    enabled: process.env.NODE_ENV !== 'development',
    accessToken: ROLLBAR_TOKEN,
    environment: ROLLBAR_ENV,
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Provider>
  );
}

export default Rollbar;