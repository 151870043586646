import moment from 'moment';

import { SEARCH_DATE_FORMAT, SEARCH_TIME_FORMAT } from './Constants';

export const renderArrayToString = (array, separator = ', ') => array.filter((s) => s !== '').join(separator);
export const renderAddress = (address) => {
    let { ward, postcode, ...otherAddress } = address;
    return renderArrayToString(Object.values(otherAddress));
};

export const stateNames = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

export const provinceNames = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  YT: 'Yukon'
};

export const formatDate = (date) => moment(date).format(SEARCH_DATE_FORMAT);
export const formatTime = (time) => moment(time, 'H:mm').format(SEARCH_TIME_FORMAT);

export const mapValues = (obj, callback) => {
    let newObj = {};

    for (let key in obj) {
        newObj[key] = callback(obj[key], key);
    }
    return newObj;
};

export const formatToTwoDecimalPlaces = (num) => (Math.round(parseFloat(num) * 100) / 100).toFixed(2);

export const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneMaskArray = [
    '+',
    '1',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const getPhoneNumber = (string) => (string || '').replace('+1', '').replace(/\D+/g, '');
export const getPhoneNumberWithCode = (string) => (string || '').replace(/[^0-9+]/g, '');

export const isValidPhone = (string) => {
    const onlyNumbers = getPhoneNumber(string);
    return onlyNumbers.length > 0 && onlyNumbers.length === 10;
};

export const getMaskForEmailOrPhoneField = (value) => isValidPhone(value) && phoneMaskArray;
