import React from "react";
import { Button } from 'react-bootstrap';

const BSButton = (props) => {
    const { className = '', icon = null, children, ...otherProps } = props;
    let classes = className.split(' ');
    if (icon !== null)
        classes.push('btn-labeled');
    
    return (
        <Button {...otherProps} className={classes.join(' ')}>
            { icon !== null && <i className={`icon fa ${icon} btn-label`}></i> }
            {props.children}
        </Button>
    );
};

export default BSButton;