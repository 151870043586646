import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

const DetailRow = ({ value, label }) => {
    const { t } = useTranslation();
    return (
        <Row>
            <Col className="font-weight-bold">{label}:</Col>
            <Col>{value ? value : <i className="text-muted">({t('intake.notFilledValue')})</i>}</Col>
        </Row>
    );
};

export default DetailRow;
