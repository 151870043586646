import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { DataTypeContext } from '../../DataTypes';
import { AccountContext } from '../../Accounts';
import CreateAccountForm from './CreateAccountForm';
import CreateAccountFormStep1 from './CreateAccountFormStep1';
import CreateAccountFormStep2 from './CreateAccountFormStep2';

const CreateAccount = ({ step = 1 }) => {
    const { username, logout } = useContext(AccountContext);
    const { dataTypes } = useContext(DataTypeContext);
    const { useIdWorkflow } = dataTypes;
    const isFirstStep = step === 1;
    
    // eslint-disable-next-line
    useEffect(() => { logout() }, []);

    if (!useIdWorkflow) {
        // old workflow
        return <CreateAccountForm />;
    }

    return (
        <>
            {isFirstStep || (!isFirstStep && username) ? (
                <Container>
                    <Row>
                        <Col md={6} sm={12} className="mx-auto">
                            {isFirstStep ? <CreateAccountFormStep1 /> : <CreateAccountFormStep2 />}
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Redirect to={{ pathname: '/' }} />
            )}
        </>
    );
};

export default CreateAccount;
