import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

const LoginModal = (props) => {
  let { show, onHide } = props;
  const { t } = useTranslation();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [response, setResponse] = useState(null);

  return (
    <Modal show={show} onHide={() => { setShowForgotPassword(false); onHide(); }}>
        <Modal.Header closeButton>
            <Modal.Title>{t('user.login')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { response !== null &&
                <p className="text-center font-weight-bold">{response}</p>
            }
            { !showForgotPassword && <LoginForm/> }
            { !showForgotPassword && 
                <p className="text-center">
                    <Button variant="link" onClick={() => setShowForgotPassword(true)}>
                        {t('user.forgotPassword')}
                    </Button> 
                </p>
            }
            { showForgotPassword && 
                <ForgotPasswordForm setShowForgotPassword={setShowForgotPassword} setConfirmationResponse={setResponse}/>
            }
        </Modal.Body>
    </Modal>
  );
}

export default LoginModal;