import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-text-mask'

const DatePickerField = ({ className, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const dateFormat = props.dateFormat || 'yyyy-MM-dd';
  const mask = dateFormat.split('').map((char) => char === '-' ? char : /\d/);
  const onChange = props.onChange;

  const styles = `notranslate ${className}`;

  return (
    <DatePicker
      {...field}
      {...props}
      className={styles}
      customInput={
       <MaskedInput
         type="text"
         mask={mask}
       />
     }
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        if (onChange !== undefined)
          onChange(val);
      }}
    />
  );
};

export default DatePickerField;