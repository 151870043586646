import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AuthenticatedRoute from './AuthenticatedRoute';
import CreateAccount from '../user/account/CreateAccount';
import ThankYou from '../user/ThankYou';
import AppointmentForm from '../user/AppointmentForm';
import Dashboard from '../user/Dashboard';
import UpcomingAppointments from '../user/UpcomingAppointments';
import ScrollToTop from '../route/ScrollToTop';
import { AccountContext } from '../Accounts';
import UpdateUser from '../user/UpdateUser';
import DeliveryPage from '../user/delivery/DeliveryPage';
import UpdateAddress from '../user/delivery/UpdateAddress';
import ConfirmBooking from '../user/ConfirmBooking';
import ProfileInformation from '../user/profile/ProfileInformation';
import LandingPage from '../user/landing/LandingPage';
import PasswordReset from '../user/PasswordReset';
import DoYouHaveId from '../user/DoYouHaveId';
import AccountExists from '../user/account/AccountExists';

export const history = createBrowserHistory();

const Router = () => {
    const { isAuthenticated, clientId } = useContext(AccountContext);
    // always redirect back button to homepage or dashboard
    history.listen((newLocation, action) => {
        if (action === 'POP') {
            if (isAuthenticated && clientId !== '') history.push('/dashboard');
            else history.push('/');
        }
    });

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/create-account">
                    <CreateAccount />
                </Route>
                <Route path="/create-account-next">
                    <CreateAccount step={2} />
                </Route>
                <Route path="/reset-password">
                    <PasswordReset />
                </Route>
                <Route path="/link2feed-id">
                    <DoYouHaveId />
                </Route>
                <Route path="/account-exists">
                    <AccountExists />
                </Route>
                <AuthenticatedRoute path="/intake">
                    <ProfileInformation />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/thank-you">
                    <ThankYou />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/dashboard">
                    <Dashboard />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/book-appointment">
                    <AppointmentForm />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/book-delivery">
                    <DeliveryPage />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/upcoming-appointments">
                    <UpcomingAppointments />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/update-user">
                    <UpdateUser />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/update-address">
                    <UpdateAddress />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/confirm-booking">
                    <ConfirmBooking />
                </AuthenticatedRoute>
                <Route path="/">
                    <LandingPage />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
