import React, { useContext } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import { DataTypeContext } from '../../DataTypes';

const AccountExists = () => {
    const { dataTypes } = useContext(DataTypeContext);
    const location = useLocation();

    const {
        questionPhrasing: { unclearMatchesText },
    } = dataTypes;
    const { t } = useTranslation();
    const isDefiniteMatch = location?.state?.definiteMatch;

    return (
        <Container>
            <Row>
                <Col md={6} sm={12} className="mx-auto text-center">
                    <h4 className="text-center">{t('account.exists.heading')}</h4>
                    {isDefiniteMatch ? (
                        <>
                            <p className="mb-4">{t('account.exists.text')}</p>
                            <div className="mx-auto d-flex flex-column">
                                <Button as={Link} variant="primary" to="/" className="mb-3">
                                    {t('account.exists.return')}
                                </Button>
                                <Button as={Link} variant="outline-dark" to="/reset-password">
                                    {t('user.forgotPassword')}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="mb-4" dangerouslySetInnerHTML={{ __html: unclearMatchesText }}></p>
                            <div className="mx-auto d-flex flex-column">
                                <Button as={Link} variant="primary" to="/">
                                    {t('account.exists.return')}
                                </Button>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default AccountExists;
