import React, { useContext } from 'react';
import { Route, Redirect } from "react-router-dom";
import { AccountContext } from '../Accounts';

const AuthenticatedRoute = ({ children, ...props }) => {
    const { isAuthenticated } = useContext(AccountContext);

    return (
        <Route
            {...props}
            render={() =>
                isAuthenticated 
                ? ( children ) 
                : ( <Redirect to={{pathname: "/"}} /> )
            }
        />
    );
};

export default AuthenticatedRoute;