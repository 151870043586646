import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { AccountContext } from '../Accounts';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { API } from '../Constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from "react-router-dom";
import AppointmentCard from './appointment/AppointmentCard';

const UpcomingAppointments = () => {
    const { clientId , firstName, lastName} = useContext(AccountContext);
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [upcomingAppointments, setUpcomingAppointments] = useState(null);
    const [appointmentsLoading, setAppointmentsLoading] = useState(true);

    const fetchAppointments = useCallback(async () => {
        setAppointmentsLoading(true); 
            const token = await executeRecaptcha("details");
            const res = await fetch(`${API.UPCOMING_APPOINTMENTS_URL}/${clientId}?token=${token}`);
            await res.json()
                .then(data => { 
                    setUpcomingAppointments(res.ok ? data : []); 
                    setAppointmentsLoading(false); 
                    if (!res.ok && data.error)
                        alert(data.error);
                })
                .catch(err => { setUpcomingAppointments([]); setAppointmentsLoading(false); });
    }, [clientId, executeRecaptcha]);

    const handleCancel = async (appointment) => {
        const confirmed = window.confirm(t('upcomingAppointments.cancelConfirmation'));
        if (confirmed) {
            const token = await executeRecaptcha("details");
            const deleteURL = `${API.DELETE_APPOINTMENTS_URL}/${appointment.location.id}/${appointment.id}`;
            fetch(deleteURL, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token: token}),
            })
                .then((response) => 
                    response.json().then(data => {
                        if (response.ok) {
                            fetchAppointments();
                        } else {
                            alert(data.message || data.error);
                        }
                    })
                )
                .catch((e) => alert('error cancelling appointment'))
        }
    };
        
    useEffect(() => {
        fetchAppointments();
    }, [fetchAppointments]);

    return (
        <Row>
            <Col md={6} sm={12} className="mx-auto text-center">
                <h4>{t('upcomingAppointments.heading')}</h4>
                <p>{firstName} {lastName}</p>
                <Badge variant="info">{t('core.clientId')}: {clientId}</Badge>
                <br/> <br/>

                { appointmentsLoading && <Spinner animation="border" variant="info"/> }

                { !appointmentsLoading && upcomingAppointments.length > 0 &&
                    upcomingAppointments.map((appointment, i) => <AppointmentCard key={`appointment-${i}`} appointment={appointment} onCancel={() => handleCancel(appointment)} />)
                }

                { !appointmentsLoading && upcomingAppointments !== null && upcomingAppointments.length === 0 && 
                    <p className='text-center text-warning'>{t('upcomingAppointments.noAppointments')}</p>
                }
                <br/>
                <Link to="/dashboard">{t('dashboard.return')}</Link>
                
            </Col>
        </Row>
    );
};

export default UpcomingAppointments;