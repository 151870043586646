import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';

import DatePickerField from '../../form/DatePickerField';
import { isRequiredField } from '../../form/RequiredAsterisk';
import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { API, FIND_CLIENT_STATUS } from '../../Constants';
import LabelField from '../../form/LabelField';
import ScrollToFirstError from '../../route/ScrollToFirstError';
import { formatDate, getPhoneNumberWithCode, getPhoneNumber } from '../../Utils';

const CreateAccountFormStep1 = () => {
    const { clientId, setClientId, setLink2FeedClientId, setFirstName, setLastName, setUsername, setEmail, setPhone, setDob, userExistsByEmailPhone } = useContext(AccountContext);
    const { dataTypes } = useContext(DataTypeContext);
    const history = useHistory();
    const { t } = useTranslation();
    const { questionPhrasing, idIsRequired, networkId, useIdWorkflow } = dataTypes;
    const [isLoading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const schema = Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        dob: Yup.date().max(new Date()).required('Date of Birth is required'),
        email: Yup.string()
            .email()
            .test('', 'Email or Phone is required', function (value) {
                const { phone } = this.parent;
                if (!phone) return value != null;
                return true;
            }),
        phone: Yup.string()
            .test('', 'Email or Phone is required', function (value) {
                const { email } = this.parent;
                if (!email) return value != null;
                return true;
            })
            .test('', 'Phone must be of 10 digits', function (value) {
                if (value !== undefined) {
                    const onlyNumbers = value.replace('+1', '').replace(/\D+/g, '');
                    if (onlyNumbers.length > 0) return onlyNumbers.length === 10;
                }
                return true;
            }),
    });

    const onSubmit = async (values) => {
        if (!isLoading) {
            setLoading(true);

            try {
                const body = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    dateOfBirth: formatDate(values.dob),
                    networkId: networkId,
                };
                if (useIdWorkflow && clientId) {
                    body.clientId = clientId;
                }

                const url = new URL(API.CNCT_ADMIN_FIND_CLIENT_BY_DETAILS_URL);
                url.search = new URLSearchParams(body).toString();
                const userName = values.email ? values.email : getPhoneNumberWithCode(values.phone);

                setFirstName(values.firstName);
                setLastName(values.lastName);
                setUsername(userName);
                setEmail(values.email);
                setPhone(getPhoneNumber(values.phone));
                setDob(formatDate(values.dob));

                const cognitoUserResult = await userExistsByEmailPhone(values.email, values.phone);
                const checkUserExists = cognitoUserResult.userExists;
                if (checkUserExists) {
                    setUsername(cognitoUserResult.username);
                    history.push({ pathname: 'account-exists', state: { definiteMatch: true } });
                } else {
                    let findResponse = null;

                    if (useIdWorkflow) {
                        findResponse = await fetch(url);
                        if (!findResponse.ok && clientId) {
                            setClientId('');
                            setLink2FeedClientId('');
                        }
                    }

                    if (idIsRequired) {
                        if (!findResponse.ok)
                            throw new Error(findResponse.status === 404 ? t('core.error.notFound') : t('core.error.unknown'));
                        const findResponseJson = await findResponse.json();

                        if (findResponseJson.status === FIND_CLIENT_STATUS.MATCHES && !clientId) {
                            history.push({ pathname: 'account-exists', state: { definiteMatch: false } });
                            return;
                        }
                    }
                    history.push({ pathname: 'create-account-next' });
                }
            } catch (err) {
                setLoading(false);
                if (err.message === 'Not Found') {
                    history.push({ pathname: 'create-account-next' });
                } else if (err.message) {
                    setResponse(err.message);
                }
            }
        }
    };

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{}}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <h4 className="text-center">{t('personal.createAccount')} - 1/2</h4>
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: questionPhrasing?.createAccountPage1 }}></p>
                    <Form.Group controlId="firstName">
                        <LabelField
                            label={questionPhrasing.firstName || t('personal.firstName')}
                            isRequired={isRequiredField(schema, 'firstName')}
                        />
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="lastName">
                        <LabelField
                            label={questionPhrasing.lastName || t('personal.lastName')}
                            isRequired={isRequiredField(schema, 'lastName')}
                        />
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="dob">
                        <LabelField
                            label={questionPhrasing.dob || t('personal.dob')}
                            isRequired={isRequiredField(schema, 'dob')}
                        />
                        <DatePickerField
                            className={!!errors.dob ? 'form-control is-invalid' : 'form-control'}
                            id="dob"
                            name="dob"
                            popperClassName="d-none" // remove this to show datepicker
                            placeholderText={dataTypes.dateFormat.toUpperCase()}
                            dateFormat={dataTypes.dateFormat}
                            maxDate={new Date()}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="email">
                        <LabelField label={ questionPhrasing.emailAndPhone || t('personal.emailAndOrPhone')} isRequired={true} />
                        <small className="text-secondary" style={{ fontSize: '80%' }}>
                            ({t('account.create.atLeastOne')})
                        </small>
                        <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                        />
                        <br />
                        <MaskedInput
                            className={errors.phone && touched.phone ? 'form-control is-invalid' : 'form-control'}
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            showMask={true}
                            mask={[
                                '+',
                                '1',
                                ' ',
                                '(',
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                            ]}
                        />
                        {values.email && values.phone && (
                            <p>
                                {t('account.create.yourLogin') + ' '}
                                <b>{values.email}</b>
                            </p>
                        )}
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                    {response !== null && <p className="text-danger">{response}</p>}
                    <Form.Group className="d-flex justify-content-end">
                        <Button as={Link} className="mr-2" variant="outline-dark" to="/">
                            {t('core.action.cancel')}
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {t(`core.action.${isLoading ? 'loading' : 'submit'}`)}
                        </Button>
                    </Form.Group>
                    <ScrollToFirstError />
                </Form>
            )}
        </Formik>
    );
};

export default CreateAccountFormStep1;
