import React, { useContext } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { DataTypeContext } from './DataTypes';

const Logo = () => {
    const { dataTypes } = useContext(DataTypeContext);
    const onImgError = (e) => { if (dataTypes.imageUrl) e.target.src = dataTypes.imageUrl || ''; };
    const onImgLoad = (e) => {
        const img = e.target;
        if (dataTypes.imageUrl && (img.naturalWidth <= 1 || img.naturalHeight <= 1)) {
            e.target.src = dataTypes.imageUrl || '';
        }
    }

    return (
        <Row>
            <Col md={6} sm={12} className="mx-auto">
                <div className="text-center">
                    <Image onError={onImgError} onLoad={onImgLoad} src='logo.png' fluid />
                </div>
            </Col>
        </Row>
    );
}

export default Logo;