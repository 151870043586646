import React, { useContext, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';

import { AccountContext } from '../../Accounts';
import ScrollToFirstError from '../../route/ScrollToFirstError';
import LabelField from '../../form/LabelField';
import { isRequiredField } from '../../form/RequiredAsterisk';
import { COGNITO_ATTRIBUTE } from '../../Constants';
import { emailRegex, getPhoneNumberWithCode, isValidPhone, getMaskForEmailOrPhoneField } from '../../Utils';

const NewUserForm = () => {
    const history = useHistory();
    const { authenticate, setClientId, getUserAttributes, setUsername, setFirstName, setLastName, setEmail, setPhone, setLoginMethod } =
        useContext(AccountContext);
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [loginResponse, setLoginResponse] = useState(null);

    const schema = Yup.object({
        emailOrPhone: Yup.string()
            .required()
            .label(t('personal.emailOrPhone'))
            .test('', 'Enter valid Email/Phone Number', (value) => emailRegex.test(value) || isValidPhone(value)),
        password: Yup.string().required().label(t('user.password')),
    });

    const handleLogin = async (values) => {
        const { emailOrPhone, password } = values;
        setLoading(true);

        let userName = '';
        if (emailRegex.test(emailOrPhone)) {
            userName = emailOrPhone;
            setLoginMethod('email');
        } else {
            userName = getPhoneNumberWithCode(emailOrPhone);
            setLoginMethod('phone');
        }
        authenticate(userName, password)
            .then(async (data) => {
                const userData = data?.idToken?.payload;
                const firstName = userData[COGNITO_ATTRIBUTE.FIRST_NAME];
                const lastName = userData[COGNITO_ATTRIBUTE.LAST_NAME];
                const email = userData[COGNITO_ATTRIBUTE.EMAIL];
                const phone = userData[COGNITO_ATTRIBUTE.PHONE];

                setFirstName(firstName);
                setLastName(lastName);
                setUsername(userName);
                if (email) {
                    setEmail(email);
                }
                if (phone) {
                    setPhone(phone.replace('+1', ''));
                }

                setLoginResponse(null);
                const attributes = await getUserAttributes();
                const clientId = attributes[COGNITO_ATTRIBUTE.CLIENT_ID];

                if (clientId && clientId !== null) {
                    setClientId(clientId);
                    history.push({
                        pathname: '/thank-you',
                        state: { isLoginRedirect: true },
                    });
                } else {
                    history.push({
                        pathname: '/intake',
                        state: { partialProfile: true },
                    });
                }
            })
            .catch((err) => {
                if (err.code && err.message) {
                    setLoginResponse(err.message);
                    setLoading(false);
                }
            });
    };

    return (
        <Formik validationSchema={schema} onSubmit={handleLogin} initialValues={{ password: '', emailOrPhone: '' }}>
            {({ handleSubmit, handleChange, touched, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <h5 className="mb-3">{t('personal.existingUserHeading')}</h5>
                    <Form.Group controlId="emailOrPhone">
                        <LabelField
                            label={t('personal.emailOrPhone')}
                            isRequired={isRequiredField(schema, 'emailOrPhone')}
                        />

                        <MaskedInput
                            className={
                                errors.emailOrPhone && touched.emailOrPhone ? 'form-control is-invalid' : 'form-control'
                            }
                            type="text"
                            name="emailOrPhone"
                            value={values.emailOrPhone}
                            showMask={true}
                            onChange={handleChange}
                            mask={getMaskForEmailOrPhoneField}
                            autoComplete="username"
                        />
                        <Form.Control.Feedback type="invalid">{errors.emailOrPhone}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <LabelField label={t('user.password')} isRequired={isRequiredField(schema, 'password')} />
                        <Form.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            isInvalid={!!errors.password}
                            autoComplete="current-password"
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    {loginResponse !== null && <p className="text-center text-danger">{loginResponse}</p>}

                    <Row className="justify-content-between">
                        <Col sm={{ span: 5, order: 'last' }} md={6} lg={5} className="d-flex mb-3">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isLoading}
                                id="loginbutton"
                                className="w-100"
                            >
                                {isLoading ? 'Loading..' : 'Login'}
                            </Button>
                        </Col>
                        <Col sm={5} md={6} lg={5} className="d-flex justify-content-center">
                            <Button as={Link} variant="link" to="/reset-password">
                                {t('user.forgotPassword')}
                            </Button>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <div className="mr-3 mt-1">{t('personal.newUserHeading')}</div>
                        <Button as={Link} variant="outline-dark" to="/link2feed-id">
                            {t('personal.createAccount')}
                        </Button>
                    </Row>

                    <ScrollToFirstError />
                </Form>
            )}
        </Formik>
    );
};

export default NewUserForm;
