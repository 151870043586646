export const LOCALE = process.env.REACT_APP_LOCALE;
export const COUNTRY = LOCALE === 'en_ca' ? 'CAN' : 'USA';
export const COGNITO_POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const SMARTY_STREETS_KEY = process.env.REACT_APP_SMARTY_STREETS_KEY;
export const ROLLBAR_TOKEN = process.env.REACT_APP_ROLLBAR_TOKEN;
export const ROLLBAR_ENV = process.env.REACT_APP_ROLLBAR_ENV;

export const API = {
    BASE_URL: process.env.REACT_APP_BASE_API_URL,
    DATA_TYPES_URL: `${process.env.REACT_APP_BASE_API_URL}/dataTypes`,
    FIND_CLIENT_URL: `${process.env.REACT_APP_BASE_API_URL}/clients/find`,
    NEW_CLIENT_URL: `${process.env.REACT_APP_BASE_API_URL}/clients`,
    CLIENT_DETAILS_URL: `${process.env.REACT_APP_BASE_API_URL}/clients/details`,
    USER_EXISTS_URL: `${process.env.REACT_APP_BASE_API_URL}/findUser`,
    AUTOCOMPLETE_URL: 'https://maps.link2feed.com/v1/autocomplete',
    // Appointments
    AVAILABLE_APPOINTMENTS_URL: `${process.env.REACT_APP_BASE_API_URL}/appointments/find`,
    CREATE_APPOINTMENTS_URL: `${process.env.REACT_APP_BASE_API_URL}/appointments/create`,
    UPDATE_APPOINTMENTS_URL: `${process.env.REACT_APP_BASE_API_URL}/appointments/update`,
    DELETE_APPOINTMENTS_URL: `${process.env.REACT_APP_BASE_API_URL}/appointments/delete`,
    UPCOMING_APPOINTMENTS_URL: `${process.env.REACT_APP_BASE_API_URL}/appointments/client/find`,
    // Delivery
    AVAILABLE_DELIVERY_URL: `${process.env.REACT_APP_BASE_API_URL}/deliveries/find`,
    // CNCT Admin
    CNCT_ADMIN_URL: process.env.REACT_APP_CNCT_ADMIN_URL,
    CNCT_ADMIN_API_V1_URL: `${process.env.REACT_APP_CNCT_ADMIN_URL}/api/v1`,
    CNCT_ADMIN_REFETCH_USER_URL: `${process.env.REACT_APP_CNCT_ADMIN_URL}/api/v1/refetch_user`,
    CNCT_ADMIN_RESET_PASSWORD_URL: `${process.env.REACT_APP_CNCT_ADMIN_URL}/api/v1/reset_password`,
    CNCT_ADMIN_FIND_CLIENT_URL: `${process.env.REACT_APP_CNCT_ADMIN_URL}/api/v1/find_client`,
    CNCT_ADMIN_FIND_CLIENT_BY_DETAILS_URL: `${process.env.REACT_APP_CNCT_ADMIN_URL}/api/v1/find_client_by_details`,
};

export const COGNITO_ATTRIBUTE = {
    CLIENT_ID: 'custom:link2feed_id',
    FIRST_NAME: 'given_name',
    LAST_NAME: 'family_name',
    EMAIL: 'email',
    EMAIL_VERIFIED: 'email_verified',
    PHONE: 'phone_number',
    PHONE_VERIFIED: 'phone_number_verified',
    SMS_CONSENT_DATE: 'custom:sms_consent_date',
    EMAIL_CONSENT_DATE: 'custom:email_consent_date',
    VOICE_CONSENT_DATE: 'custom:voice_consent_date',
    INFO_CONSENT_DATE: 'custom:info_consent_date',
    LOGIN_METHOD: 'custom:login_method',
};

export const APPOINTMENTS_DATE_FORMAT = 'ddd MMMM Do h:mm A';

export const SEARCH_DATE_FORMAT = 'yyyy-MM-DD';

export const SEARCH_TIME_FORMAT = 'HH:mm';

export const PROVISION_DISPLAY_TYPE = {
    CHECKBOX: 'checkbox',
    COUNT: 'count',
    CURRENCY: 'currency',
    WEIGHT: 'weight',
};

export const FIND_CLIENT_STATUS = {
    CNCT_CLIENT_EXISTS: 'cnct_client_exists',
    MATCHES: 'matches',
};
