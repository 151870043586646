import React from 'react';
import { connect } from 'formik';

class ScrollToFirstError extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
     const errorElement =  document.getElementsByClassName('is-invalid')[0]
      if(errorElement) {
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ScrollToFirstError);