import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import UserForm from './UserForm';
import NewUserForm from './NewUserForm';

const LandingPage = () => {
    const { dataTypes } = useContext(DataTypeContext);
    const { useIdWorkflow, foodBankName, welcomeMessage } = dataTypes;
    const { logout } = useContext(AccountContext);

    // eslint-disable-next-line
    useEffect(() => { logout() }, []);

    return (
        <Container>
            <Row>
                <Col md={6} sm={12} className="mx-auto">
                    <h4 className="text-center">{foodBankName} Client Enrollment</h4>
                    <h6 className="text-center" dangerouslySetInnerHTML={{ __html: welcomeMessage }}></h6>
                    {useIdWorkflow ? <NewUserForm /> : <UserForm />}
                </Col>
            </Row>
        </Container>
    );
};

export default LandingPage;
