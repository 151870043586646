import React from "react";
import { Form } from 'react-bootstrap';

const LabelField = ({ ...props }) => {
    const { label, isRequired } = props;
    const labelField = <Form.Label dangerouslySetInnerHTML={{ __html: label }}></Form.Label>;
    const requiredAsteriskField = isRequired 
        ? <span className="required-attribute text-info">&nbsp;*&nbsp;</span>
        : null;

    return (
        <>
            { labelField }
            { requiredAsteriskField }
        </>
    );
};

export default LabelField;