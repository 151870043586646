import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Form, Col } from 'react-bootstrap';
import { isRequiredField } from "./RequiredAsterisk";
import LabelField from "./LabelField";

const RadiosField = ({ ...props }) => {
    const { errors, setFieldValue } = useFormikContext();
    const { name, schema, types, label, value} = props;
    let [checkedValue, setCheckedValue] = useState(value || []);

    return (
        <Form.Group controlId={name}>
            <LabelField 
                label={label}
                isRequired={isRequiredField(schema, name)}
            />
            <div className="row" key="inline-checkbox" id={name} tabIndex="0" >
                {
                    types.map((t) => (
                        <Col sm="6" key={`${name}-${t.name}`}>
                            <Form.Check
                                inline label={t.label} type="radio" name={name}
                                id={`inline-${name}-${t.name}`}
                                value={t.name}
                                checked={value === t.name}
                                onChange={ (e) => {
                                    const targetValue = e.target.value;
                                    const isChecked = e.target.checked;

                                    if (isChecked && checkedValue !== targetValue) {
                                        checkedValue = targetValue;
                                    }

                                    setCheckedValue(checkedValue);
                                    setFieldValue(name, checkedValue);
                                  }
                                }
                                isInvalid={!!errors[name]}
                            />
                        </Col>
                    ))
                }
            </div>
        </Form.Group>
    );
};

export default RadiosField;
