import React, { useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import { useFormikContext } from 'formik';
import { COUNTRY, API } from '../../Constants';

const PeliasAutocomplete = (props) => {
    const { setFieldValue, errors } = useFormikContext();
    const [value, setValue] = useState(props.inputProps.value || '');
    const [suggestions, setSuggestions] = useState([]);

    function getSuggestions(value) {
        fetch(`${API.AUTOCOMPLETE_URL}?boundary.country=${COUNTRY}&layers=address&text=${value}`)
            .then((response) => response.json())
            .then((data) => setSuggestions(data.features));
    }

    const theme = {
        container: 'autosuggest',
        input: `${!!errors.addressLine1 ? 'form-control is-invalid' : 'form-control'}`,
        suggestionsContainer: 'dropdown',
        suggestionsList: `dropdown-menu w-100 ${suggestions.length ? 'show' : ''}`,
        suggestion: 'dropdown-item text-truncate',
        suggestionHighlighted: 'active',
    };

    return (
        <>
            <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    getSuggestions(value);
                }}
                onSuggestionSelected={(_, { suggestion }) => {
                    setFieldValue('latitude', suggestion.geometry.coordinates[1]);
                    setFieldValue('longitude', suggestion.geometry.coordinates[0]);
                    setFieldValue('addressLine1', suggestion.properties.name);
                    setFieldValue('city', suggestion.properties.locality);
                    setFieldValue('state', suggestion.properties.region);
                    if (suggestion.properties.county !== undefined)
                        setFieldValue('county', suggestion.properties.county);
                    if (suggestion.properties.postalcode !== undefined)
                        setFieldValue('zip', suggestion.properties.postalcode);
                }}
                getSuggestionValue={(suggestion) => suggestion.properties.name}
                renderSuggestion={(suggestion) => {
                    const {
                        properties: { name, locality, region },
                    } = suggestion;
                    const text = [name, locality, region].filter(Boolean).join(', ');
                    return (
                        <span key={suggestion.properties.id} title={text}>
                            {text}
                        </span>
                    );
                }}
                inputProps={{
                    ...props.inputProps,
                    value: value,
                    onChange: (_, { newValue, method }) => {
                        setValue(newValue);
                        setFieldValue('addressLine1', newValue);
                    },
                }}
                // highlightFirstSuggestion={true}
                theme={theme}
            />
        </>
    );
};

export default PeliasAutocomplete;
