import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';

import { emailRegex, getMaskForEmailOrPhoneField, isValidPhone } from '../Utils';
import LabelField from '../form/LabelField';
import { isRequiredField } from '../form/RequiredAsterisk';
import { AccountContext } from '../Accounts';

const PasswordReset = () => {
    const { t } = useTranslation();
    const { sendPasswordReset } = useContext(AccountContext);
    const [isLoading, setLoading] = useState(false);
    const [loginResponse, setLoginResponse] = useState(null);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [username, setUsername] = useState(null);

    const schema = Yup.object({
        emailOrPhone: Yup.string()
            .required()
            .label(t('personal.emailOrPhone'))
            .test('', 'Enter valid Email/Phone Number', (value) => emailRegex.test(value) || isValidPhone(value)),
    });

    const handlePasswordReset = async ({ emailOrPhone }) => {
        setLoading(true);
        try {
            const response = await sendPasswordReset(emailOrPhone);

            if (!response.ok) {
                throw new Error(response.status === 404 ? t('core.error.notFound') : t('core.error.unknown'));
            }

            setUsername(emailOrPhone);
            setIsPasswordReset(true);
        } catch (err) {
            if (err.message) {
                setLoginResponse(err.message);
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <Container>
            <Row>
                <Col md={6} sm={12} className="mx-auto">
                    <h4 className="text-center">{t('user.passwordReset.heading')}</h4>
                    {!isPasswordReset ? (
                        <Formik
                            validationSchema={schema}
                            onSubmit={handlePasswordReset}
                            initialValues={{ emailOrPhone: '' }}
                        >
                            {({ handleSubmit, handleChange, touched, values, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <p className="mb-4 text-center">{t('user.passwordReset.text')}</p>
                                    <Form.Group controlId="emailOrPhone">
                                        <LabelField
                                            label={t('personal.emailOrPhone')}
                                            isRequired={isRequiredField(schema, 'emailOrPhone')}
                                        />

                                        <MaskedInput
                                            className={
                                                errors.emailOrPhone && touched.emailOrPhone
                                                    ? 'form-control is-invalid'
                                                    : 'form-control'
                                            }
                                            type="text"
                                            name="emailOrPhone"
                                            value={values.emailOrPhone}
                                            showMask={true}
                                            onChange={handleChange}
                                            mask={getMaskForEmailOrPhoneField}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.emailOrPhone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {loginResponse !== null && (
                                        <p className="text-center text-danger">{loginResponse}</p>
                                    )}
                                    <Form.Group className="d-flex justify-content-end">
                                        <Button as={Link} className="mr-2" variant="outline-dark" to="/">
                                            {t('core.action.cancel')}
                                        </Button>
                                        <Button variant="primary" type="submit" disabled={isLoading}>
                                            {t(`core.action.${isLoading ? 'loading' : 'submit'}`)}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <div className="text-center d-flex flex-column">
                            <p className="mb-4">
                                <Trans
                                    i18nKey="user.passwordReset.thankYouMessage"
                                    components={[<b />]}
                                    values={{ username }}
                                />
                            </p>
                            <Button as={Link} variant="primary" to="/">
                                {t('core.action.returnToLogin')}
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordReset;
