import React from 'react';
import { Form, Button, Row, Col, Card, Badge, Spinner, Alert } from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import AddressForm from '../AddressForm';
import DatePickerField from '../../form/DatePickerField';
import { isRequiredField } from '../../form/RequiredAsterisk';
import SelectField from '../../form/SelectField';
import CheckboxesField from '../../form/CheckboxesField';
import RadiosField from '../../form/RadiosField';
import LabelField from '../../form/LabelField';
import ScrollToFirstError from '../../route/ScrollToFirstError';

const ProfileForm = ({
    schema,
    onSubmit,
    initialValues,
    dataTypes,
    isLoading,
    isSessionExpired,
    clientLoading,
    findClient,
}) => {
    const { questionPhrasing, useIdWorkflow } = dataTypes;
    const enabledFields = dataTypes.enabledFields || [];
    const requiredFields = dataTypes.requiredFields || [];
    const { t } = useTranslation();
    const location = useLocation();
    const isPartialProfile =
        (location && location.state !== undefined && location.state?.partialProfile) || isSessionExpired;
    const isEdit = location && location.state !== undefined && location.state?.edit && location.state?.edit === true;

    return (
        <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue }) => (
                <Row>
                    <Col md={6} sm={12} className="mx-auto">
                        <Form noValidate onSubmit={handleSubmit}>
                            {!isEdit && !useIdWorkflow && (
                                <div className="text-center">
                                    <Badge variant="dark">Page 2/2</Badge>
                                </div>
                            )}
                            <h4 className="text-center">Please enter the following household details to continue</h4>
                            {dataTypes.householdMessage && (
                                <h6
                                    className="text-center"
                                    dangerouslySetInnerHTML={{ __html: dataTypes.householdMessage }}
                                ></h6>
                            )}

                            {clientLoading && (
                                <div className="text-center">
                                    <Spinner animation="border" variant="info" />
                                </div>
                            )}

                            {!clientLoading && (
                                <>
                                    <Card>
                                        <Card.Header className="cardhouseholddetails">My Information</Card.Header>
                                        <Card.Body>
                                            {isEdit && (
                                                <>
                                                    <Form.Group controlId="clientFirstName">
                                                        <LabelField
                                                            label={questionPhrasing.firstName || t('personal.firstName')}
                                                            isRequired={isRequiredField(schema, 'firstName')}
                                                        />
                                                        <Form.Control
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            value={values.firstName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.firstName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="clientLastName">
                                                        <LabelField
                                                            label={questionPhrasing.lastName || t('personal.lastName')}
                                                            isRequired={isRequiredField(schema, 'lastName')}
                                                        />
                                                        <Form.Control
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            value={values.lastName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.lastName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </>
                                            )}

                                            {isPartialProfile && isSessionExpired && (
                                                <Alert variant="danger">
                                                    {t('session.sessionExpiredNotice', { dob_label: questionPhrasing.dob })}
                                                </Alert>
                                            )}

                                            {(isPartialProfile || isEdit) && (
                                                <Form.Group controlId="dob">
                                                    <LabelField
                                                        label={questionPhrasing.dob || t('personal.dob')}
                                                        isRequired={isRequiredField(schema, 'dob')}
                                                    />
                                                    <DatePickerField
                                                        className={!!errors.dob ? 'form-control is-invalid' : 'form-control'}
                                                        id="dob"
                                                        name="dob"
                                                        popperClassName="d-none" // remove this to show datepicker
                                                        placeholderText={dataTypes.dateFormat.toUpperCase()}
                                                        dateFormat={dataTypes.dateFormat}
                                                        maxDate={new Date()}
                                                        showYearDropdown
                                                        dateFormatCalendar="MMMM"
                                                        yearDropdownItemNumber={15}
                                                        scrollableYearDropdown
                                                        autoComplete="off"
                                                        onChange={(date) => findClient(date)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            {enabledFields.includes('gender') && (
                                                <Form.Group controlId="gender">
                                                    <LabelField
                                                        label={questionPhrasing.gender || t('intake.gender')}
                                                        isRequired={isRequiredField(schema, 'gender')}
                                                    />
                                                    <SelectField
                                                        as="select"
                                                        name="gender"
                                                        value={values.gender}
                                                        isInvalid={!!errors.gender}
                                                        options={dataTypes.genders}
                                                        includeEmpty={true}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.gender}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            {enabledFields.includes('maritalStatus') && (
                                                <Form.Group controlId="maritalStatus">
                                                    <LabelField
                                                        label={questionPhrasing.maritalStatus || t('intake.maritalStatus')}
                                                        isRequired={isRequiredField(schema, 'maritalStatus')}
                                                    />
                                                    <SelectField
                                                        as="select"
                                                        name="maritalStatus"
                                                        value={values.maritalStatus}
                                                        isInvalid={!!errors.maritalStatus}
                                                        options={dataTypes.maritalStatuses}
                                                        includeEmpty={true}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.maritalStatus}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}



                                            {enabledFields.includes('isHispanicLatino') && (
                                                <RadiosField
                                                    schema={schema}
                                                    types={dataTypes.isHispanicLatino}
                                                    name="isHispanicLatino"
                                                    value={values.isHispanicLatino}
                                                    label={questionPhrasing.isHispanicLatino || t('intake.isHispanicLatino')}
                                                />
                                            )}

                                            {enabledFields.includes('ethnicity') && (
                                                <CheckboxesField
                                                    schema={schema}
                                                    types={dataTypes.ethnicities}
                                                    name="ethnicityType"
                                                    value={values.ethnicityType}
                                                    label={questionPhrasing.ethnicities || (enabledFields.includes('isHispanicLatino') ? t('intake.ethnicities_alternate') : t('intake.ethnicities'))}
                                                />
                                            )}

                                            {enabledFields.includes('selfIdentificationType') && (
                                                <CheckboxesField
                                                    schema={schema}
                                                    types={dataTypes.selfIdentificationTypes}
                                                    name="selfIdentificationType"
                                                    value={values.selfIdentificationType}
                                                    label={questionPhrasing.selfIdentifiesAs || t('intake.selfIdentifiesAs')}
                                                />
                                            )}

                                            {enabledFields.includes('educationType') && (
                                                <Form.Group controlId="educationType">
                                                    <LabelField
                                                        label={questionPhrasing.education || t('intake.education')}
                                                        isRequired={isRequiredField(schema, 'educationType')}
                                                    />
                                                    <SelectField
                                                        as="select"
                                                        name="educationType"
                                                        value={values.educationType}
                                                        isInvalid={!!errors.educationType}
                                                        options={dataTypes.educationTypes}
                                                        includeEmpty={true}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.educationType}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            {enabledFields.includes('employmentType') && (
                                                <Form.Group controlId="employmentType">
                                                    <LabelField
                                                        label={questionPhrasing.employment || t('intake.employment')}
                                                        isRequired={isRequiredField(schema, 'employmentType')}
                                                    />
                                                    <SelectField
                                                        as="select"
                                                        name="employmentType"
                                                        value={values.employmentType}
                                                        isInvalid={!!errors.employmentType}
                                                        options={dataTypes.employmentTypes}
                                                        includeEmpty={true}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.employmentType}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Card.Body>
                                    </Card>
                                    <br/>

                                    <Card>
                                        <Card.Header className="cardhouseholddetails">{t('intake.otherHouseholdMembers')}</Card.Header>
                                        <Card.Body>
                                            <div className='font-weight-bold'>
                                                <LabelField
                                                    label={questionPhrasing.householdMembers || ''}
                                                    isRequired={false}
                                                />
                                            </div>
                                            <FieldArray
                                                name="hhMembers"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.hhMembers && values.hhMembers.length > 0 ? (
                                                            values.hhMembers.map((hhm, index) => (
                                                                <div key={index}>
                                                                    <Card className="cardhousehold">
                                                                        <Card.Header className="cardhouseholddetails">
                                                                            Household Member {index + 1}
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Form.Row>
                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    md="12"
                                                                                    controlId="firstName"
                                                                                >
                                                                                    <LabelField
                                                                                        label={
                                                                                            questionPhrasing.firstName ||
                                                                                            t('personal.firstName')
                                                                                        }
                                                                                        isRequired={true}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name={`hhMembers.${index}.firstName`}
                                                                                        placeholder="First Name"
                                                                                        onChange={handleChange}
                                                                                        value={hhm.firstName || ''}
                                                                                        isInvalid={
                                                                                            errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            !!errors.hhMembers[index]
                                                                                                .firstName
                                                                                        }
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            errors.hhMembers[index]
                                                                                                .firstName}
                                                                                    </Form.Control.Feedback>
                                                                                </Form.Group>

                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    md="12"
                                                                                    controlId="lastName"
                                                                                >
                                                                                    <LabelField
                                                                                        label={
                                                                                            questionPhrasing.lastName ||
                                                                                            t('personal.lastName')
                                                                                        }
                                                                                        isRequired={true}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name={`hhMembers.${index}.lastName`}
                                                                                        placeholder="Last Name"
                                                                                        onChange={handleChange}
                                                                                        value={hhm.lastName || ''}
                                                                                        isInvalid={
                                                                                            errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            !!errors.hhMembers[index]
                                                                                                .lastName
                                                                                        }
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            errors.hhMembers[index]
                                                                                                .lastName}
                                                                                    </Form.Control.Feedback>
                                                                                </Form.Group>

                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    md="12"
                                                                                    controlId="dob"
                                                                                >
                                                                                    <LabelField
                                                                                        label={
                                                                                            questionPhrasing.dob ||
                                                                                            t('personal.dob')
                                                                                        }
                                                                                        isRequired={true}
                                                                                    />
                                                                                    <DatePickerField
                                                                                        className={
                                                                                            errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            !!errors.hhMembers[index]
                                                                                                .dob
                                                                                                ? 'form-control is-invalid'
                                                                                                : 'form-control'
                                                                                        }
                                                                                        name={`hhMembers.${index}.dob`}
                                                                                        popperClassName="d-none" // remove this to show datepicker
                                                                                        placeholderText={dataTypes.dateFormat.toUpperCase()}
                                                                                        dateFormat={
                                                                                            dataTypes.dateFormat
                                                                                        }
                                                                                        maxDate={new Date()}
                                                                                        showYearDropdown
                                                                                        dateFormatCalendar="MMMM"
                                                                                        yearDropdownItemNumber={15}
                                                                                        scrollableYearDropdown
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {errors.hhMembers &&
                                                                                            errors.hhMembers[index] &&
                                                                                            errors.hhMembers[index].dob}
                                                                                    </Form.Control.Feedback>
                                                                                </Form.Group>

                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    className="labelDOBEstimated"
                                                                                    md="12"
                                                                                    controlId={`hhMembers.${index}.hhmEstimatedDOB`}
                                                                                >
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label={t('intake.dobEstimated')}
                                                                                        type="checkbox"
                                                                                        name={`hhMembers.${index}.hhmEstimatedDOB`}
                                                                                        value={
                                                                                            hhm.hhmEstimatedDOB || false
                                                                                        }
                                                                                        checked={
                                                                                            hhm.hhmEstimatedDOB || false
                                                                                        }
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </Form.Group>

                                                                                {enabledFields.includes('gender') && (
                                                                                    <Form.Group
                                                                                        as={Col}
                                                                                        md="12"
                                                                                        controlId="hhmGender"
                                                                                    >
                                                                                        <LabelField
                                                                                            label={
                                                                                                questionPhrasing.gender ||
                                                                                                t('intake.gender')
                                                                                            }
                                                                                            isRequired={true}
                                                                                        />
                                                                                        <SelectField
                                                                                            as="select"
                                                                                            name={`hhMembers.${index}.hhmGender`}
                                                                                            isInvalid={
                                                                                                errors.hhMembers &&
                                                                                                errors.hhMembers[
                                                                                                    index
                                                                                                ] &&
                                                                                                !!errors.hhMembers[
                                                                                                    index
                                                                                                ].hhmGender
                                                                                            }
                                                                                            options={dataTypes.genders}
                                                                                            includeEmpty={true}
                                                                                        />

                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.hhMembers &&
                                                                                                errors.hhMembers[
                                                                                                    index
                                                                                                ] &&
                                                                                                errors.hhMembers[index]
                                                                                                    .hhmGender}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                )}

                                                                                {enabledFields.includes(
                                                                                    'relationship'
                                                                                ) && (
                                                                                    <Form.Group
                                                                                        as={Col}
                                                                                        md="12"
                                                                                        controlId="hhmRelationship"
                                                                                    >
                                                                                        <LabelField
                                                                                            label={
                                                                                                questionPhrasing.relationships ||
                                                                                                t(
                                                                                                    'intake.relationships'
                                                                                                )
                                                                                            }
                                                                                            isRequired={true}
                                                                                        />
                                                                                        <SelectField
                                                                                            as="select"
                                                                                            name={`hhMembers.${index}.hhmRelationship`}
                                                                                            isInvalid={
                                                                                                errors.hhMembers &&
                                                                                                errors.hhMembers[
                                                                                                    index
                                                                                                ] &&
                                                                                                !!errors.hhMembers[
                                                                                                    index
                                                                                                ].hhmRelationship
                                                                                            }
                                                                                            options={
                                                                                                dataTypes.relationships
                                                                                            }
                                                                                            includeEmpty={true}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.hhMembers &&
                                                                                                errors.hhMembers[
                                                                                                    index
                                                                                                ] &&
                                                                                                errors.hhMembers[index]
                                                                                                    .hhmRelationship}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                )}
                                                                            </Form.Row>
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md="6"
                                                                                className="float-right"
                                                                            >
                                                                                <Button
                                                                                    className="float-right"
                                                                                    variant="danger"
                                                                                    size="sm"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.remove(index);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-trash-o fa-lg"></i>
                                                                                </Button>
                                                                            </Form.Group>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <br />
                                                        <Button
                                                            className="btn btn-labeled"
                                                            variant="info"
                                                            onClick={() => {
                                                                arrayHelpers.push('');
                                                            }}
                                                        >
                                                            <i className="btn text-white btn-label icon fa fa-plus"></i>
                                                            Add
                                                        </Button>
                                                    </div>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>
                                    <br/>

                                    <Card>
                                        <Card.Header className="cardhouseholddetails">
                                            {t('intake.householdDetailsHeading')}
                                        </Card.Header>
                                        <Card.Body>
                                            <Card>
                                                <Card.Header className="cardhouseholddetails">
                                                    {t('address.heading')}
                                                </Card.Header>
                                                <Card.Body>
                                                    <AddressForm
                                                        values={values}
                                                        handleChange={handleChange}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors}
                                                        schema={schema}
                                                    />
                                                    {enabledFields.includes('housingType') && (
                                                        <Form.Group controlId="housingType">
                                                            <LabelField
                                                                label={questionPhrasing.housingType || t('intake.housingType')}
                                                                isRequired={isRequiredField(schema, 'housingType')}
                                                            />
                                                            <SelectField
                                                                as="select"
                                                                name="housingType"
                                                                value={values.housingType}
                                                                isInvalid={!!errors.housingType}
                                                                options={dataTypes.housingTypes}
                                                                includeEmpty={true}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.housingType}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}

                                                </Card.Body>
                                            </Card>
                                            <br/>

                                            {enabledFields.includes('socialProgramTypes') && (
                                                <CheckboxesField
                                                    schema={schema}
                                                    types={dataTypes.socialProgramTypes}
                                                    name="socialProgramTypes"
                                                    value={values.socialProgramTypes}
                                                    onlyOneWhen={[
                                                        'declined_to_answer',
                                                        'do_not_know',
                                                        'no_benefits',
                                                        'other_benefits'
                                                    ]}
                                                    label={questionPhrasing.socialPrograms || t('intake.socialPrograms')}
                                                    isRequired={isRequiredField(schema, 'socialProgramTypes')}
                                                />
                                            )}

                                            {enabledFields.includes('dietaryConsiderationTypes') && (
                                                <CheckboxesField
                                                    schema={schema}
                                                    types={dataTypes.dietaryConsiderationTypes}
                                                    name="dietaryConsiderationTypes"
                                                    value={values.dietaryConsiderationTypes}
                                                    label={
                                                        questionPhrasing.dietaryConsiderationTypes ||
                                                        t('intake.dietaryConsiderationTypes')
                                                    }
                                                    isRequired={isRequiredField(schema, 'dietaryConsiderationTypes')}
                                                />
                                            )}
                                            <br />

                                            {enabledFields.includes('referredBy') && (
                                                <Form.Group controlId="referredBy">
                                                    <LabelField
                                                        label={questionPhrasing.referredBy || t('intake.referredBy')}
                                                        isRequired={isRequiredField(schema, 'referredBy')}
                                                    />
                                                    <SelectField
                                                        as="select"
                                                        name="referredBy"
                                                        value={values.referredBy}
                                                        isInvalid={!!errors.referredBy}
                                                        options={dataTypes.referredBy}
                                                        includeEmpty={true}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.referredBy}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            {enabledFields.includes('languageTypes') && (
                                                <Form.Group controlId="languageTypes">
                                                    <LabelField
                                                        label={questionPhrasing.languageTypes || t('intake.languageTypes')}
                                                        isRequired={isRequiredField(schema, 'languageTypes')}
                                                    />
                                                    {/* Needs to be updated with a separate component */}
                                                    <Select
                                                        options={dataTypes.languageTypes.map((type) => ({
                                                            label: type.label,
                                                            value: type.name,
                                                        }))}
                                                        isMulti
                                                        value={values.languageTypes?.map((type) => ({
                                                            label: type,
                                                            value: type,
                                                        }))}
                                                        required={isRequiredField(schema, 'languageTypes')}
                                                        name="languageTypes"
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'languageTypes',
                                                                e?.map((opt) => opt.value)
                                                            );
                                                        }}
                                                        className={!!errors.languageTypes ? 'is-invalid invalid-language' : ''}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.languageTypes}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            <Form.Row>
                                                {(enabledFields.includes('incomeType') || enabledFields.includes('income')) && (
                                                    <Form.Group as={Col} md="12" controlId="incomeType">
                                                        <div className="d-block">
                                                            <LabelField
                                                                label={questionPhrasing.incomeType || t('intake.incomeTypeHeading')}
                                                                isRequired={false}
                                                            />
                                                        </div>

                                                        <Form.Row>
                                                            {enabledFields.includes('incomeType') && (
                                                                <Form.Group as={Col} md="6">
                                                                    <LabelField
                                                                        label={t('intake.incomeType')}
                                                                        isRequired={isRequiredField(schema, 'incomeType')}
                                                                    />
                                                                    <SelectField
                                                                        as="select"
                                                                        name="incomeType"
                                                                        value={values.incomeType}
                                                                        isInvalid={!!errors.incomeType}
                                                                        options={dataTypes.income_sourceTypes}
                                                                        includeEmpty={true}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.incomeType}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            )}
                                                            {enabledFields.includes('income') && (
                                                                <Form.Group as={Col} md="6">
                                                                        <LabelField
                                                                            label={t('intake.incomeAmount')}
                                                                            isRequired={isRequiredField(schema, 'incomeAmount')}
                                                                        />
                                                                        <Form.Control
                                                                            type="number"
                                                                            name="incomeAmount"
                                                                            value={values.incomeAmount}
                                                                            placeholder={t('intake.incomeAmount')}
                                                                            onChange={handleChange}
                                                                            isInvalid={!!errors.incomeAmount}
                                                                        />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.incomeAmount}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            )}
                                                        </Form.Row>
                                                    </Form.Group>
                                                )}
                                            </Form.Row>

                                            {enabledFields.includes('otherIncomes') && (
                                                <CheckboxesField
                                                    schema={schema}
                                                    types={dataTypes.income_sourceTypes}
                                                    name="otherIncomes"
                                                    value={values.otherIncomes}
                                                    label={questionPhrasing.otherIncomes || t('intake.otherIncomes')}
                                                />
                                            )}

                                            {enabledFields.includes('expenseTypes') && (
                                                <Card>
                                                    <Card.Header className="cardhouseholddetails">Expense Types</Card.Header>
                                                    <Card.Body>
                                                        <FieldArray
                                                            name="expenseTypes"
                                                            render={(arrayHelpers) => (
                                                                <div>
                                                                    {values.expenseTypes && values.expenseTypes.length > 0 ? (
                                                                        values.expenseTypes.map((type, index) => (
                                                                            <div key={index}>
                                                                                <Form.Row>
                                                                                    <Form.Group
                                                                                        as={Col}
                                                                                        md="6"
                                                                                        controlId="expenseType"
                                                                                    >
                                                                                        <LabelField
                                                                                            label={
                                                                                                questionPhrasing.expenses ||
                                                                                                t('intake.expenses')
                                                                                            }
                                                                                            isRequired={requiredFields.includes('expenseTypes')}
                                                                                        />
                                                                                        <SelectField
                                                                                            as="select"
                                                                                            name={`expenseTypes.${index}.type`}
                                                                                            isInvalid={
                                                                                                errors.expenseTypes &&
                                                                                                errors.expenseTypes[index] &&
                                                                                                !!errors.expenseTypes[index]
                                                                                                    .type
                                                                                            }
                                                                                            options={dataTypes.expenseTypes}
                                                                                            includeEmpty={true}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.expenseTypes &&
                                                                                                errors.expenseTypes[index] &&
                                                                                                errors.expenseTypes[index].type}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>

                                                                                    <Form.Group
                                                                                        as={Col}
                                                                                        md="6"
                                                                                        controlId="expenseAmount"
                                                                                    >
                                                                                        <Form.Label>&nbsp;</Form.Label>
                                                                                        <Form.Control
                                                                                            type="number"
                                                                                            name={`expenseTypes.${index}.amount`}
                                                                                            placeholder="Expense Amount"
                                                                                            onChange={handleChange}
                                                                                            value={type.amount || ''}
                                                                                            isInvalid={
                                                                                                errors.expenseTypes &&
                                                                                                errors.expenseTypes[index] &&
                                                                                                !!errors.expenseTypes[index]
                                                                                                    .amount
                                                                                            }
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.expenseTypes &&
                                                                                                errors.expenseTypes[index] &&
                                                                                                errors.expenseTypes[index]
                                                                                                    .amount}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>

                                                                                    <Form.Group
                                                                                        as={Col}
                                                                                        md="12"
                                                                                        className="text-right"
                                                                                    >
                                                                                        <Button
                                                                                            variant="danger"
                                                                                            size="sm"
                                                                                            onClick={() => {
                                                                                                arrayHelpers.remove(index);
                                                                                                setFieldValue(
                                                                                                    'expenseCount',
                                                                                                    values.expenseCount - 1
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Remove
                                                                                        </Button>
                                                                                    </Form.Group>
                                                                                </Form.Row>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <br />
                                                                    <Button
                                                                        variant="success"
                                                                        onClick={() => {
                                                                            arrayHelpers.push('');
                                                                            setFieldValue(
                                                                                'expenseCount',
                                                                                values.expenseCount + 1
                                                                            );
                                                                        }}
                                                                    >
                                                                        Add expense
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            <br />
                                        </Card.Body>
                                    </Card>
                                    <br />

                                    <Form.Group className="text-center">
                                        <Button variant="primary" type="submit" disabled={isLoading} id="nextButton">
                                            {isLoading ? 'Loading..' : 'Review'}
                                        </Button>
                                    </Form.Group>
                                    <ScrollToFirstError />
                                </>
                            )}
                        </Form>
                    </Col>
                </Row>
            )}
        </Formik>
    );
};

export default ProfileForm;
