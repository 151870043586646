import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card, Container } from 'react-bootstrap';

import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { formatToTwoDecimalPlaces } from '../../Utils';
import DetailRow from './DetailRow';

const ProfileFormReview = ({ onSubmit, onCancel, formData, isLoading }) => {
    const { firstName, lastName, dob } = useContext(AccountContext);
    const { t } = useTranslation();
    const { dataTypes } = useContext(DataTypeContext);
    const enabledFields = dataTypes.enabledFields || [];
    const ethnicityLabel = enabledFields.includes('isHispanicLatino') ? t('intake.ethnicities_alternate') : t('intake.ethnicities');

    const data = useMemo(() => {
        if (dataTypes && formData) {
            return {
                ...formData,
                hhMembers: [
                    {
                        // if firstname, lastname, dob were filled in prev steps
                        firstName: formData?.firstName || firstName,
                        lastName: formData?.lastName || lastName,
                        dob: formData?.dob || dob,
                        gender: dataTypes?.genders?.find((type) => type.name === formData?.gender)?.label,
                        maritalStatus: dataTypes?.maritalStatuses?.find((type) => type.name === formData?.maritalStatus)
                            ?.label,
                    },
                    ...(formData?.hhMembers.map((member) => ({
                        firstName: member?.firstName,
                        lastName: member?.lastName,
                        dob: member?.dob,
                        gender: dataTypes?.genders?.find((type) => type.name === member?.hhmGender)?.label,
                        relationship: dataTypes?.relationships?.find((type) => type.name === member?.hhmRelationship)
                            ?.label,
                    })) || []),
                ],
                dietaryConsiderationTypes: formData?.dietaryConsiderationTypes
                    ?.map((type) => dataTypes?.dietaryConsiderationTypes.find((t) => t.name === type)?.label)
                    ?.join(', '),
                housingType: dataTypes?.housingTypes?.find((type) => type.name === formData?.housingType)?.label,
                referredBy: dataTypes?.referredBy?.find((type) => type.name === formData?.referredBy)?.label,
                selfIdentificationType: formData?.selfIdentificationType
                    ?.map((type) => dataTypes?.selfIdentificationTypes.find((t) => t.name === type)?.label)
                    ?.join(', '),
                ethnicity: formData?.ethnicityType
                    ?.map((type) => dataTypes?.ethnicities.find((t) => t.name === type)?.label)
                    ?.join(', '),
                isHispanicLatino: dataTypes?.isHispanicLatino?.find((type) => type.name === formData?.isHispanicLatino)?.label,
                educationType: dataTypes?.educationTypes?.find((type) => type.name === formData?.educationType)?.label,
                employmentType: dataTypes?.employmentTypes?.find((type) => type.name === formData?.employmentType)
                    ?.label,
                incomeType: dataTypes?.income_sourceTypes?.find((type) => type.name === formData?.incomeType)?.label,
                languageTypes: formData?.languageTypes?.join(', '),
                expenseTypes: formData?.expenseTypes
                    ?.map((expense) => ({
                        ...expense,
                        label: dataTypes?.expenseTypes.find((t) => t.name === expense.type)?.label,
                    }))
                    ?.map((expense) => `${expense.label} - $${formatToTwoDecimalPlaces(expense.amount || 0)}`)
                    .join(', '),
                socialProgramTypes: formData?.socialProgramTypes
                    ?.map((type) => dataTypes?.socialProgramTypes.find((t) => t.name === type)?.label)
                    ?.join(', '),
            };
        }
    }, [dataTypes, dob, firstName, formData, lastName]);

    return (
        <Container>
            <Row>
                <Col md={8} sm={12} className="mx-auto">
                    <h4 className="text-center">{t('intake.reviewHeading')}</h4>
                    <p className="text-center mb-4">{t('intake.reviewText')}</p>
                    <h5>{t('intake.hhMemberHeading')}</h5>
                    <Row className="mb-5">
                        {data.hhMembers.map((member, i) => (
                            <Col className="mb-3" xs={6} key={`${member.firstName}_${i}`}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            {member.firstName} {member.lastName}
                                        </Card.Title>
                                        {enabledFields.includes('relationship') && member.relationship && (
                                            <Card.Subtitle className="mb-2 text-muted">
                                                {t('intake.review.relationship')} {member.relationship}
                                            </Card.Subtitle>
                                        )}
                                        <Row>
                                            <Col className="font-weight-bold">{t('personal.dob')}:</Col>
                                            <Col>{moment(member.dob).format(dataTypes.dateFormat.toUpperCase())}</Col>
                                        </Row>
                                        {enabledFields.includes('gender') && (
                                            <Row>
                                                <Col className="font-weight-bold">{t('intake.gender')}:</Col>
                                                <Col>{member.gender}</Col>
                                            </Row>
                                        )}
                                        {enabledFields.includes('maritalStatus') && member.maritalStatus && (
                                            <Row>
                                                <Col className="font-weight-bold">{t('intake.maritalStatus')}:</Col>
                                                <Col>{member.maritalStatus}</Col>
                                            </Row>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <h5>{t('intake.clientDetailsHeading')}</h5>
                    <Row className="mb-5">
                        <Col>
                            <Row>
                                <Col className="font-weight-bold">{t('address.heading')}:</Col>
                                {data?.noFixedAddress && <Col>{t('address.noFixedAddress')}</Col>}
                                {data?.addressDeclinedToAnswer && <Col>{t('address.addressDeclinedToAnswer')}</Col>}
                                {!data?.noFixedAddress && !data?.addressDeclinedToAnswer && (
                                    <Col>
                                        <p className="mb-2">{data?.addressLine1}</p>
                                        {data?.addressLine2 && <p className="mb-2">{data?.addressLine2}</p>}
                                        <p className="mb-2">
                                            {data?.city}, {data?.state}, {data?.zip}
                                        </p>
                                    </Col>
                                )}
                            </Row>
                            {enabledFields.includes('housingType') && (
                                <DetailRow label={t('intake.housingType')} value={data?.housingType} />
                            )}
                            {enabledFields.includes('referredBy') && (
                                <DetailRow label={t('intake.referredBy')} value={data?.referredBy} />
                            )}
                            {enabledFields.includes('isHispanicLatino') && (
                                <DetailRow label={t('intake.isHispanicLatino')} value={data?.isHispanicLatino} />
                            )}
                            {enabledFields.includes('ethnicity') && (
                                <DetailRow label={ethnicityLabel} value={data?.ethnicity} />
                            )}
                            {enabledFields.includes('selfIdentificationType') && (
                                <DetailRow label={t('intake.selfIdentifiesAs')} value={data?.selfIdentificationType} />
                            )}
                            {enabledFields.includes('educationType') && (
                                <DetailRow label={t('intake.education')} value={data?.educationType} />
                            )}
                            {enabledFields.includes('employmentType') && (
                                <DetailRow label={t('intake.employment')} value={data?.employmentType} />
                            )}
                            {(enabledFields.includes('incomeType') || enabledFields.includes('income')) && (
                                <DetailRow
                                    label={t('intake.incomeTypeHeading')}
                                    value={
                                        `${data?.incomeType || ''}${data?.incomeType ? ' - ' : ''}$${data?.incomeAmount || 0}`
                                    }
                                />
                            )}
                        </Col>
                    </Row>

                    <h5>{t('intake.householdDetailsHeading')}</h5>
                    <Row className="mb-5">
                        <Col>
                            {enabledFields.includes('languageTypes') && (
                                <DetailRow label={t('intake.languageTypes')} value={data?.languageTypes} />
                            )}
                            {enabledFields.includes('expenseTypes') && (
                                <DetailRow label={t('intake.expenses')} value={data?.expenseTypes} />
                            )}
                            {enabledFields.includes('socialProgramTypes') && (
                                <DetailRow label={t('intake.socialPrograms')} value={data?.socialProgramTypes} />
                            )}
                            {enabledFields.includes('dietaryConsiderationTypes') && (
                                <DetailRow
                                    label={t('intake.dietaryConsiderationTypes')}
                                    value={data?.dietaryConsiderationTypes}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5">
                        <Button
                            variant="primary"
                            id="backButton"
                            disabled={isLoading}
                            onClick={onCancel}
                            className="mr-3"
                        >
                            Back
                        </Button>
                        <Button variant="primary" id="nextButton" disabled={isLoading} onClick={onSubmit}>
                            {isLoading ? 'Loading...' : 'Save & Next'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ProfileFormReview;
