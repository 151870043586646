import React, { useContext, useState, useEffect } from 'react';
import { AccountContext } from '../Accounts';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = (props) => {
    const { t } = useTranslation();
    const { username, sendPasswordReset } = useContext(AccountContext);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        const response = sendPasswordReset(username);
        response.then((res) => {
            if (!res.ok) {
                setResponse(res.status === 404 ? t('core.error.notFound') : t('core.error.unknown'));
            } else {
                setResponse(t('user.passwordReset.thankYouMessage', { username: username }));
            }
        });
    }, [ username, setResponse, t, sendPasswordReset]);

    return (
        <div>
            { response !== null &&
                <p className="text-center font-weight-bold">{response}</p>
            }
        </div>
    );
}

export default ForgotPasswordForm;
