import React, { useMemo } from 'react';
import { Container, Image } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Router from './route/Router';
import Logo from './Logo';
import { Account } from './Accounts';
import { DataType } from './DataTypes';
import { RECAPTCHA_SITE_KEY } from './Constants';
import Rollbar from './Rollbar';

function App() {
    const year = useMemo(() => new Date().getFullYear(), []);
    return (
        <Rollbar>
            <DataType>
                <Account>
                    <Container>
                        <br />
                        <Logo />
                        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                            <Router />
                        </GoogleReCaptchaProvider>
                        <br />
                    </Container>
                    <div className="text-center">
                        <Image src="/favicon.ico" width="15" height="15" />
                        &nbsp; Powered by Link2Feed (Copyright © {year})
                    </div>
                </Account>
            </DataType>
        </Rollbar>
    );
}

export default App;
