import React, { useContext, useCallback } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { APPOINTMENTS_DATE_FORMAT } from '../../Constants';
import { renderAddress } from '../../Utils';
import BSButton from '../../form/BSButton';
import { DataTypeContext } from '../../DataTypes';

const AppointmentCard = (props) => {
    const { t } = useTranslation();
    const { appointment, showActions = true, onCancel } = props;
    const { isDeliveryEnabled } = useContext(DataTypeContext);

    const getBookingPageLink = useCallback(() => {
        if (isDeliveryEnabled && appointment?.vromo_integration) {
            return '/book-delivery';
        }

        return '/book-appointment';
    }, [appointment, isDeliveryEnabled]);

    return (
        <Card className="mb-3 text-center">
            <Card.Body>
                <Card.Title>{moment(appointment.datetime).format(APPOINTMENTS_DATE_FORMAT)}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{appointment.program.name}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{appointment.location.name}</Card.Subtitle>
                <Card.Text>{renderAddress(appointment.location.address)}</Card.Text>
                {showActions && (
                    <Row>
                        <Col xs="6">
                            <BSButton
                                as={Link}
                                to={{ pathname: getBookingPageLink(), state: { existingAppointment: appointment } }}
                                icon="fa-pencil-square-o"
                                className="text-white"
                                variant="warning"
                            >
                                {t('core.action.modify')}
                            </BSButton>
                        </Col>
                        <Col xs="6">
                            <Card.Link as={BSButton} icon="fa-times" variant="danger" onClick={onCancel}>
                                {t('core.action.cancel')}
                            </Card.Link>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    );
};

export default AppointmentCard;
