import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCALE } from './Constants';

// the translations
// (tip move them in a JSON file and import them)
// @todo create a file like above
const resources = {
    en: {
        translation: {
            core: {
                welcome: 'Welcome',
                clientId: 'Client ID',
                important: 'Important',
                action: {
                    modify: 'Modify',
                    cancel: 'Cancel',
                    submit: 'Submit',
                    loading: 'Loading..',
                    update: 'Update',
                    returnToLogin: 'Return to Login',
                },
                error: {
                    notFound: 'Not Found',
                    unknown: "We're sorry, something went wrong. Please try again. If you continue to have issue, please contact us."
                }
            },
            account: {
                password: 'Password',
                confirmPassword: 'Confirm Password',
                consentContactEmailWarning: 'Please add your email in Account Information later',
                consentContactSMSWarning: 'Please add your phone number in Account Information later',
                exists: {
                    heading: 'Account Exists',
                    text: 'Based on the information you have provided, we believe that you already have a CNCT Account. You are able to login with the account that you already setup, or perform our password reset process if you cannot remember your login details.',
                    return: 'Return to Login',
                },
                id: {
                    doNotHaveButton: "I Don't Have This",
                    notFound: 'That ID Could Not Be Found',
                },
                create: {
                    atLeastOne: 'At Least One',
                    yourLogin: 'Your Login ID is',
                },
                consent: {
                    shareInformationLabel: 'Consent to Share Information',
                    toContactLabel: ' Consent to Contact',
                    toContactEmail: 'Consent to Contact via Email',
                    toContactSMS: 'Consent to Contact via Text Message (SMS)',
                    toContactVoice: 'Consent to Contact via Voice',
                },
            },
            address: {
                heading: 'Address',
                addressLine1: 'Address Line 1',
                addressLine2: 'Address Line 2',
                city: 'City',
                county: 'County',
                state: 'State',
                zipcode: 'Zipcode',
                noFixedAddress: 'No Fixed Address',
                addressDeclinedToAnswer: 'Prefer Not to Answer',
            },
            personal: {
                firstName: 'First Name',
                lastName: 'Last Name',
                emailAndPhone: 'Email and Phone',
                emailAndOrPhone: 'Email and/or Phone Number',
                dob: 'Date of Birth',
                newUserHeading: 'New User?',
                existingUserHeading: 'Welcome Back',
                emailOrPhone: 'Email or Phone Number',
                createAccount: 'Create Account',
            },
            intake: {
                gender: 'Gender Identity',
                ethnicities: 'Ethnicity',
                ethnicities_alternate: 'Race',
                isHispanicLatino: 'Ethnicity',
                housingType: 'Housing Type',
                education: 'Highest Education Level Completed',
                employment: 'Employment Type',
                selfIdentifiesAs: 'Self-Identifies As',
                incomeType: 'Income Type',
                incomeAmount: 'Income Amount',
                incomeTypeHeading: 'Primary Income',
                otherIncomes: 'Other Income Sources',
                maritalStatus: 'Marital Status',
                householdMembers: 'How many other people live in your household (not including you)?',
                referredBy: 'Referred By',
                relationships: 'Relationship',
                socialPrograms: 'Receiving the Following Household Social Programs',
                expenses: 'Expense Type',
                dietaryConsiderationTypes: 'Dietary Considerations',
                languageTypes: 'Languages',
                dobEstimated: 'Is Date of Birth Estimated?',
                reviewHeading: 'Review Profile Updates',
                reviewText: 'Please check profile updates before saving.',
                hhMemberHeading: 'Household Members',
                otherHouseholdMembers: 'Other Household Members',
                clientDetailsHeading: 'Client Details',
                householdDetailsHeading: 'General Household Information',
                notFilledValue: 'not filled',
                review: {
                    relationship: 'Relationship to Me:',
                },
            },
            appointment: {
                heading: 'Book Services',
                agency: 'Location',
                program: 'Program',
                startDate: 'Book Services Between',
                endDate: 'and',
                selectTimeSlot: 'Please select a preferred time slot from the following.',
                noSlots:
                    'We’re unable to book an appointment for this service. This may be because you are either not eligible for the selected service or have exceeded the allowed number of visits.\n\nPlease try selecting a different location or service.\n\nIf you continue to experience issues, we recommend reaching out directly to the organization for further assistance.',
                seeMore: 'See more',
                modifyMessage: 'You are modifying the following service.',
                book: 'Book',
                booking: 'Booking',
            },
            upcomingAppointments: {
                heading: 'Upcoming Services',
                noAppointments: 'No upcoming appointments found.',
                cancelConfirmation: 'Are you sure you want to cancel this service?',
            },
            dashboard: {
                heading: 'User Dashboard',
                bookServices: 'Book Services',
                bookPickup: 'Book Pickup',
                bookDelivery: 'Book Delivery',
                upcomingServices: 'View Upcoming Services',
                return: 'Return to Dashboard',
                updateInfo: 'Update Account Info',
                updateProfile: 'Update Profile Information',
            },
            user: {
                username: 'Username',
                password: 'Password',
                login: 'Login',
                verification: 'Verification',
                verify: 'Verify',
                verified: 'Verified',
                verifyNow: 'Verify Now',
                resendCode: 'Resend Code',
                verificationCode: 'Verification Code',
                verificationCodeSent:
                    'A verification code has been sent to your email or phone to complete your account setup.',
                verificationText:
                    'Please verify your contact details so that you can reset your password in case you forget.',
                email: 'Email',
                phone: 'Phone Number',
                forgotPassword: 'Forgot my Password',
                forgotPasswordCodeSent:
                    'Your verified {{value}} has been sent a recovery code. Please enter it here and your new password to complete the password reset.',
                forgotPasswordSuccess:
                    'Your new password has been set successfully, please login below with your new password.',
                newPassword: 'New Password',
                confirmNewPassword: 'New Password (Again)',
                noConfirmedEmailPhoneMessage:
                    'We cannot reset password as this account has not been verified. Please contact {{network}} to reset your password for you.',
                passwordReset: {
                    heading: 'Password Reset',
                    text: 'Please enter your email and/or phone number to reset your password. If you have an account, you will receive an email or text message shortly with instructions. If you continue to have challenges, please reach out to the organization by phone or email.',
                    thankYouMessage:
                        'Thank you! An email or text has been sent with instructions. Please check your email or text at {{username}} for a link to reset your password.',
                },
            },
            updateUser: {
                heading: 'Update Information',
                currentPassword: 'Current Password',
            },
            session: {
                apiSessionNotFound: 'Client session not found.',
                sessionExpiredNotice:
                    'Your session has expired due to inactivity. Please re-enter your {{dob_label}} and submit the form.',
                sessionExpiredGeneralNotice: 'Your session has expired due to inactivity. Please reload the page',
            },
            confirmAddress: {
                heading: 'Please confirm your address below for the delivery',
                useThisAddress: 'Use This Address',
                updateMyAddress: 'Update my Address',
            },
            updateAddress: {
                heading: 'Please add your home address for delivery',
            },
            delivery: {
                heading: 'Book Delivery',
                startDate: 'Book Delivery Between',
                noSlots: 'We’re sorry, no delivery is available for your location currently.',
                consentContactEnabled: 'You will receive a confirmation email/SMS prior to your delivery.',
                consentContactDisabled: `You currently have not allowed the organization to contact you. The organization's delivery system uses text (SMS) or email. Please opt in if you would like delivery confirmations and notifications. You can change these settings by updating your <0>Account Information</0>.`,
                provisionsLabel:
                    '<0>Foods/Items Requested</0> <1>(Please note, we may not be able to accommodate all requests)</1>',
                deliveryNotesLabel:
                    '<0>Delivery Notes</0> <1>(Special delivery instructions for example, “please ring the 2nd buzzer from the top” or “leave it on the porch”)</1>',
                nonDeliveryNotesLabel: '<0>Notes</0>',
                consentContactWarning:
                    "The organization's delivery system uses text (SMS) or email. Please opt in if you would like delivery confirmations and notifications.",
            },
            pickup: {
                heading: 'Book Pickup',
                startDate: 'Book Pickup Between',
            },
        },
    },
    en_ca: {
        translation: {
            address: {
                state: 'Province',
                zipcode: 'Postal Code',
            },
            intake: {
                ethnicities: 'Racial Identity',
            },
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: LOCALE,
        fallbackLng: 'en',

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
