import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';

import { APPOINTMENTS_DATE_FORMAT, COGNITO_ATTRIBUTE } from '../Constants';
import { AccountContext } from '../Accounts';
import { DataTypeContext } from '../DataTypes';

const ConfirmBooking = () => {
    const { t } = useTranslation();
    const {
        clientId,
        username,
        selectedAppointmentSlot,
        selectedAppointmentLocation,
        isDeliveryAppointment,
        getUserAttributes,
    } = useContext(AccountContext);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const [isConsentContactSelected, setIsConsentContactSelected] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const getConsentContactInfo = async () => {
            const attributes = await getUserAttributes();
            const consentContactEmail = attributes[COGNITO_ATTRIBUTE.EMAIL_CONSENT_DATE];
            const consentContactSMS = attributes[COGNITO_ATTRIBUTE.SMS_CONSENT_DATE];
            const email = attributes[COGNITO_ATTRIBUTE.EMAIL];
            const phone = attributes[COGNITO_ATTRIBUTE.PHONE];
            setIsConsentContactSelected(
                (Boolean(consentContactEmail) && email) || (Boolean(consentContactSMS) && phone)
            );
            setIsLoaded(true);
        };
        getConsentContactInfo();
    }, [getUserAttributes]);

    const getTimeSlotLabel = useCallback(() => {
        const dateString = moment(selectedAppointmentSlot?.availableDateTime).format(APPOINTMENTS_DATE_FORMAT);
        if (isDeliveryAppointment) {
            return selectedAppointmentSlot.choiceStr;
        }

        return dateString;
    }, [isDeliveryAppointment, selectedAppointmentSlot]);

    return (
        <Row>
            <Col md={6} sm={12} className="mx-auto">
                <br />
                <Alert variant="success">
                    <Alert.Heading
                        dangerouslySetInnerHTML={{
                            __html: dataTypes.thankYouMessage
                                ? dataTypes.thankYouMessage
                                : 'Thank you for registering with foodbank',
                        }}
                    ></Alert.Heading>
                    <hr />
                    <p>
                        Your Login ID is <b>{username}</b>
                    </p>
                    {isDeliveryAppointment ? (
                        <p>
                            Your delivery request is scheduled for&nbsp;
                            <b>{getTimeSlotLabel()}</b>
                        </p>
                    ) : (
                        <p>
                            Your appointment is scheduled at&nbsp;
                            <b>{getTimeSlotLabel()}</b>
                            &nbsp;for {selectedAppointmentLocation.nameWithAddress}
                        </p>
                    )}
                    {isLoaded && isDeliveryEnabled && isDeliveryAppointment ? (
                        isConsentContactSelected ? (
                            <p>{t('delivery.consentContactEnabled')}</p>
                        ) : (
                            <p>
                                <Trans
                                    i18nKey="delivery.consentContactDisabled"
                                    components={<Link to="/update-user" />}
                                />
                            </p>
                        )
                    ) : null}

                    <p>
                        Your Client ID is <b>{clientId}</b>
                    </p>
                    <p>Please provide this when you visit.</p>
                </Alert>
                <p className="text-center">
                    <Link to="/dashboard">{t('dashboard.return')}</Link>
                </p>
            </Col>
        </Row>
    );
};

export default ConfirmBooking;
