import React, { createContext, useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { API } from './Constants';

const DataTypeContext = createContext();

const DataType = (props) => {
    const [dataTypes, setDataTypes] = useState({});
    const [dataTypesLoaded, setDataTypesLoaded] = useState(false);
    const [isDeliveryEnabled, setIsDeliveryEnabled] = useState(false);
    const [useProvisionsAndNotes, setUseProvisionsAndNotes] = useState(false);

    async function fetchData() {
        const res = await fetch(API.DATA_TYPES_URL);
        await res
            .json()
            .then((res) => {
                setDataTypes(res);
                setDataTypesLoaded(true);
                setIsDeliveryEnabled(Boolean(res?.vromoIntegrationNetwork));
                setUseProvisionsAndNotes(Boolean(res?.useProvisionsAndNotes));
            })
            .catch(() => {
                setDataTypes({});
                setDataTypesLoaded(false);
                setIsDeliveryEnabled(false);
                setUseProvisionsAndNotes(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const loadingResponse = (
        <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
        </div>
    );
    const normalResponse = (
        <DataTypeContext.Provider value={{ dataTypes, isDeliveryEnabled, useProvisionsAndNotes }}> {props.children} </DataTypeContext.Provider>
    );

    return dataTypesLoaded ? normalResponse : loadingResponse;
};

export { DataType, DataTypeContext };
