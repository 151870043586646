import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../Accounts';
import { API } from '../../Constants';
import ConfirmAddress from './ConfirmAddress';
import AppointmentForm from '../../user/AppointmentForm';

const DeliveryPage = () => {
    const { clientId, setClientProfileId, setClientDetails, isJustUpdatedAddress, setIsJustUpdatedAddress } =
        useContext(AccountContext);
    const [addressObject, setAddressObject] = useState(null);
    const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const fetchClientDataAndPopulate = useCallback(async () => {
        const token = await executeRecaptcha('details');
        const res = await fetch(`${API.CLIENT_DETAILS_URL}/${clientId}?token=${token}`);
        const clientData = await res.json();
        setClientDetails(clientData);

        setAddressObject({
            addressLine1: clientData.addressLine1,
            addressLine2: clientData.addressLine2 ? clientData.addressLine2 : '',
            city: clientData.city,
            county: clientData.county,
            state: clientData.state,
            zip: clientData.zipCode,
            noFixedAddress: clientData.noFixedAddress,
        });

        if (clientData.noFixedAddress) {
            setClientProfileId(clientData?.clientProfileId);
            history.push({ pathname: '/update-address' });
        }
    }, [clientId, executeRecaptcha, history, setClientDetails, setClientProfileId]);

    useEffect(() => {
        fetchClientDataAndPopulate();
    }, [fetchClientDataAndPopulate]);

    useEffect(() => {
        if (isJustUpdatedAddress) {
            confirmAddressHandler();
            setIsJustUpdatedAddress(false);
        }
    }, [isJustUpdatedAddress, setIsJustUpdatedAddress]);

    const confirmAddressHandler = () => setIsAddressConfirmed(true);

    return (
        <div>
            {isAddressConfirmed || isJustUpdatedAddress ? (
                <AppointmentForm isDelivery />
            ) : (
                <ConfirmAddress addressObject={addressObject} confirmAddressHandler={confirmAddressHandler} />
            )}
        </div>
    );
};

export default DeliveryPage;
