import React, { useContext } from 'react';
import { Row, Col, Badge, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AccountContext } from '../Accounts';
import { DataTypeContext } from '../DataTypes';

const Dashboard = () => {
    const { clientId, firstName, lastName } = useContext(AccountContext);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const hasAppointment = dataTypes.agencies.length > 0;
    const { t } = useTranslation();

    return (
        <Row>
            <Col lg={6} md={8} sm={12} className="mx-auto text-center">
                <h4>{t('dashboard.heading')}</h4>
                <p>
                    {t('core.welcome')}, {firstName} {lastName}
                </p>
                <Badge variant="info">
                    {t('core.clientId')}: {clientId}
                </Badge>
                <br /> <br />
                <Nav as={Col} md={9} xs={8} className="mx-auto flex-column">
                    {hasAppointment && isDeliveryEnabled ? (
                        <>
                            <Nav.Link as={Link} className="btn btn-primary mb-3 d-block" to="/book-delivery">
                                {t('dashboard.bookDelivery')}
                            </Nav.Link>
                            {<Nav.Link as={Link} className="btn btn-primary mb-3 d-block" to="/book-appointment">
                                {t('dashboard.bookPickup')}
                            </Nav.Link>}
                        </>
                    ) : (
                        hasAppointment && (
                            <Nav.Link as={Link} className="btn btn-primary mb-3" to="/book-appointment">
                                {t('dashboard.bookServices')}
                            </Nav.Link>
                        )
                    )}
                    {hasAppointment && (
                        <Nav.Link as={Link} className="btn btn-primary mb-3" to="/upcoming-appointments">
                            {t('dashboard.upcomingServices')}
                        </Nav.Link>
                    )}
                    <Nav.Link as={Link} className="btn btn-primary mb-3" to="/update-user">
                        {t('dashboard.updateInfo')}
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        className="btn btn-primary mb-3"
                        to={{ pathname: '/intake', state: { edit: true } }}
                    >
                        {t('dashboard.updateProfile')}
                    </Nav.Link>
                </Nav>
            </Col>
        </Row>
    );
};

export default Dashboard;
