import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { COGNITO_ATTRIBUTE, API } from '../Constants';
import { AccountContext } from '../Accounts';
import { DataTypeContext } from '../DataTypes';
import LabelField from '../form/LabelField';
import RequiredAsterisk, { isRequiredField } from '../form/RequiredAsterisk';
import ScrollToFirstError from '../route/ScrollToFirstError';
import { formatDate } from '../Utils';

export default function UpdateUser() {
    const { t } = useTranslation();

    const { getUserAttributes, updateUserAttributes, updateUserPassword, setEmail, setPhone, loginMethod, setUsername } =
        useContext(AccountContext);

    const [cognitoAttributes, setCognitoAttributes] = useState({
        email: '',
        phone_number: '',
        consentContactEmail: false,
        consentContactSMS: false,
        consentContactVoice: false,
        loginMethod: loginMethod,
    });

    const [errorState, setErrorState] = useState([]);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(0);
    const [originalPhone, setOriginalPhone] = useState(null);
    const [originalEmail, setOriginalEmail] = useState(null);
    const [alert, setAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const enabledFields = dataTypes.enabledFields || [];

    const scrollTo = (ref) => {
        if (ref && ref !== null) {
            ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        const fetchAttributes = async () => {
            const attributes = await getUserAttributes();

            const { email, phone_number, sub } = attributes;
            const consentContactEmail = attributes[COGNITO_ATTRIBUTE.EMAIL_CONSENT_DATE];
            const consentContactSMS = attributes[COGNITO_ATTRIBUTE.SMS_CONSENT_DATE];
            const consentContactVoice = attributes[COGNITO_ATTRIBUTE.VOICE_CONSENT_DATE];

            setOriginalPhone(phone_number);
            setOriginalEmail(email);

            const trackedAttributes = {
                email,
                phone_number,
                sub,
                consentContactEmail: Boolean(consentContactEmail),
                consentContactSMS: Boolean(consentContactSMS),
                consentContactVoice: Boolean(consentContactVoice),
            };

            setCognitoAttributes(prevAttributes => ({
                ...prevAttributes,
                ...trackedAttributes
            }));
        };

        fetchAttributes();
    }, [getUserAttributes]);

    useEffect(() => {
        if (errorState.length > 0) {
            setAlert(
                <Alert
                    ref={scrollTo}
                    style={{ margin: '15px 0' }}
                    variant="danger"
                    dismissible
                    onClose={() => setSubmittedState(0)}
                >
                    {errorState.map((msg) => (
                        <span style={{ display: 'block' }}>{msg}</span>
                    ))}
                </Alert>
            );
        } else if (errorState.length === 0 && successState === true) {
            setAlert(
                <Alert
                    ref={scrollTo}
                    style={{ margin: '15px 0' }}
                    variant="success"
                    dismissible
                    onClose={() => setSubmittedState(0)}
                >
                    Update completed successfully.
                </Alert>
            );
        }
        setLoading(false);
    }, [submittedState, errorState, successState, setLoading]);

    const handleFormSubmit = async (values) => {
        const emailExpression =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const attributesToUpdate = [];
        const errors = [];
        setLoading(true);
        let shouldSetEmail = false;
        let shouldSetPhone = false;
        let shouldSetConsentContact = false;

        if (values?.email && values?.email !== cognitoAttributes?.email && emailExpression.test(values?.email)) {
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: 'email',
                    Value: values.email,
                })
            );

            shouldSetEmail = true;
        }

        if (
            values?.phone_number &&
            values?.phone_number !== cognitoAttributes?.phone_number &&
            values?.phone_number.replace('+1', '').replace(/\D+/g, '').length === 10
        ) {
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: 'phone_number',
                    Value: values.phone_number.replace(/[^0-9+]/g, ''),
                })
            );

            shouldSetPhone = true;
        }

        if (values?.consentContactEmail !== Boolean(cognitoAttributes?.consentContactEmail)) {
            const today = formatDate(new Date());
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: COGNITO_ATTRIBUTE.EMAIL_CONSENT_DATE,
                    Value: values.consentContactEmail ? today : '',
                })
            );

            shouldSetConsentContact = true;
        }

        if (values?.consentContactSMS !== Boolean(cognitoAttributes?.consentContactSMS)) {
            const today = formatDate(new Date());
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: COGNITO_ATTRIBUTE.SMS_CONSENT_DATE,
                    Value: values.consentContactSMS ? today : '',
                })
            );

            shouldSetConsentContact = true;
        }

        if (values?.consentContactVoice !== Boolean(cognitoAttributes?.consentContactVoice)) {
            const today = formatDate(new Date());
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: COGNITO_ATTRIBUTE.VOICE_CONSENT_DATE,
                    Value: values.consentContactVoice ? today : '',
                })
            );

            shouldSetConsentContact = true;
        }

        let passwordSetSuccess = false;

        if (values?.password && values?.password === values?.confirmPassword) {
            //change password
            try {
                // Lets change a password.
                await updateUserPassword(values.currentPassword, values.password);

                passwordSetSuccess = true;
            } catch (e) {
                console.log(e);
                errors.push(`Either your password could not be set, or your current password was incorrect.`);
            }
        } else if (values?.currentPassword) {
            try {
                // We need to change it to what it already is to test we have a correct password to change the other fields.
                await updateUserPassword(values.currentPassword, values.currentPassword);

                passwordSetSuccess = true;
            } catch (e) {
                console.log(e);
                errors.push(`Your current password was incorrect.`);
            }
        }

        if (passwordSetSuccess && attributesToUpdate.length > 0) {
            try {
                await updateUserAttributes(attributesToUpdate);

                let body = {
                    id: cognitoAttributes.sub,
                };

                if (shouldSetEmail) {
                    setEmail(values.email);
                    body = {
                        ...body,
                        old_email: originalEmail,
                    };
                }

                if (shouldSetPhone) {
                    setPhone(values.phone_number.replace('+1', ''));
                    body = {
                        ...body,
                        old_phone: originalPhone,
                    };
                }

                if (shouldSetEmail || shouldSetPhone || shouldSetConsentContact) {
                    setUsername(loginMethod === 'email' ? values.email : values.phone_number);
                    // send update to CNCT-Admin that user has been updated
                    const url = new URL(API.CNCT_ADMIN_REFETCH_USER_URL);
                    url.search = new URLSearchParams(body).toString();

                    fetch(url, { mode: 'no-cors' });
                }
            } catch (e) {
                console.log(e);
                errors.push(
                    `Something went wrong updating your email, phone number or communication consent. Please try again.`
                );
            }
        }

        if (errors.length > 0) {
            setErrorState(errors);
            setSuccessState(false);
        } else {
            setSuccessState(true);
        }

        setSubmittedState(submittedState + 1);
    };

    const schema = Yup.object({
        email: Yup.string()
          .email()
          .when('loginMethod', {
            is: 'email',
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired(),
          }),
        phone_number: Yup.string()
          .test('', 'Phone must be of 10 digits', function (value) {
            if (value !== undefined) {
                const onlyNumbers = value.replace('+1', '').replace(/\D+/g, '');
                if (onlyNumbers.length > 0 || loginMethod === 'phone')
                    return onlyNumbers.length === 10;
            }
            return true;
        }),
        password: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
            'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character like (@#$&*!?)'
        ),
        confirmPassword: Yup.string().when('password', {
            is: (value) => value && value.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password'), null], "Passwords don't match")
                .required('Confirm Password is required'),
            otherwise: Yup.string(),
        }),
        currentPassword: Yup.string().required('Current password is required to save changes.'),
        consentContactEmail: Yup.boolean().when('email', {
            is: (value) => value && value.length,
            then: Yup.boolean(),
            otherwise: Yup.boolean().test('', 'Please add your email', (value) => !value),
        }),
        consentContactSMS: Yup.boolean().when('phone_number', {
            is: (value) => {
                if (value !== undefined) {
                    const onlyNumbers = value.replace('+1', '').replace(/\D+/g, '');
                    if (onlyNumbers.length > 0) return onlyNumbers.length === 10;
                }
                return false;
            },
            then: Yup.boolean(),
            otherwise: Yup.boolean().test('', 'Please add your phone number', (value) => !value),
        }),
        consentContactVoice: Yup.boolean().when('phone_number', {
            is: (value) => {
                if (value !== undefined) {
                    const onlyNumbers = value.replace('+1', '').replace(/\D+/g, '');
                    if (onlyNumbers.length > 0) return onlyNumbers.length === 10;
                }
                return false;
            },
            then: Yup.boolean(),
            otherwise: Yup.boolean().test('', 'Please add your phone number', (value) => !value),
        }),
    });

    return (
        <>
            {submittedState ? alert : null}
            <Formik
                validationSchema={schema}
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={cognitoAttributes}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Row>
                        <Col md={6} sm={12} className="mx-auto">
                            <h4>{t('updateUser.heading')}</h4>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="email">
                                    <LabelField label={t('personal.emailAndPhone')} isRequired={true} />
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        value={values.email}
                                    />
                                    <br />
                                    <MaskedInput
                                        className={
                                            errors.phone_number && touched.phone_number
                                                ? 'form-control is-invalid'
                                                : 'form-control'
                                        }
                                        type="text"
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange}
                                        showMask={true}
                                        mask={[
                                            '+',
                                            '1',
                                            ' ',
                                            '(',
                                            /[1-9]/,
                                            /\d/,
                                            /\d/,
                                            ')',
                                            ' ',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                    />
                                    {values.email && values.phone_number && (
                                        <p>
                                            Your Login ID is <b>{loginMethod === 'email' ? values.email : values.phone_number}</b>
                                        </p>
                                    )}
                                    <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="consentContact">
                                    <Form.Label>
                                        Consent to Contact
                                        <RequiredAsterisk schema={schema} name="consentContact" />
                                    </Form.Label>
                                    <p dangerouslySetInnerHTML={{ __html: dataTypes.consentDisclaimer }}></p>
                                    { enabledFields.includes('emailConsent') && (
                                    <Form.Check
                                        name="consentContactEmail"
                                        label="Consent to Contact via Email"
                                        onChange={handleChange}
                                        isInvalid={!!errors.consentContactEmail}
                                        feedback={errors.consentContactEmail}
                                        id="consentContactEmail"
                                        checked={Boolean(values.consentContactEmail)}
                                        value={Boolean(values.consentContactEmail)}
                                    />
                                    )}
                                    { enabledFields.includes('smsConsent') && (
                                    <Form.Check
                                        name="consentContactSMS"
                                        label="Consent to Contact via Text Message (SMS)"
                                        onChange={handleChange}
                                        isInvalid={!!errors.consentContactSMS}
                                        feedback={errors.consentContactSMS}
                                        id="consentContactSMS"
                                        checked={Boolean(values.consentContactSMS)}
                                        value={Boolean(values.consentContactSMS)}
                                    />
                                    )}
                                    { enabledFields.includes('voiceConsent') && (
                                    <Form.Check
                                        name="consentContactVoice"
                                        label={t('account.consent.toContactVoice')}
                                        onChange={handleChange}
                                        isInvalid={!!errors.consentContactVoice}
                                        feedback={errors.consentContactVoice}
                                        id="consentContactVoice"
                                        checked={Boolean(values.consentContactVoice)}
                                        value={Boolean(values.consentContactVoice)}
                                    />
                                    )}
                                </Form.Group>
                                {isDeliveryEnabled && (
                                    <p className="fontsize">
                                        The organization's delivery system uses text (SMS) or email. Please opt in if you
                                        would like delivery confirmations and notifications.
                                    </p>
                                )}
                                <Form.Group controlId="password">
                                    <LabelField
                                        label={t('account.password')}
                                        isRequired={isRequiredField(schema, 'password')}
                                    />
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="confirmPassword">
                                    <LabelField
                                        label={t('account.confirmPassword')}
                                        isRequired={isRequiredField(schema, 'confirmPassword')}
                                    />
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="currentPassword">
                                    <LabelField label={t('updateUser.currentPassword')} isRequired={true} />
                                    <Form.Control
                                        type="password"
                                        name="currentPassword"
                                        placeholder="Current Password"
                                        onChange={handleChange}
                                        isInvalid={!!errors.currentPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.currentPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="text-center">
                                    <Button variant="info" type="submit" disabled={isLoading}>
                                        {isLoading ? 'Loading..' : 'Save'}
                                    </Button>
                                </Form.Group>
                                <p className="text-center">
                                    <Link to="/dashboard">{t('dashboard.return')}</Link>
                                </p>
                                <ScrollToFirstError />
                            </Form>
                        </Col>
                    </Row>
                )}
            </Formik>
        </>
    );
}
