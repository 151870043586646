import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { isRequiredField } from '../../form/RequiredAsterisk';
import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import LoginModal from '../LoginModal';
import MaskedInput from 'react-text-mask';
import SelectField from '../../form/SelectField';
import { useTranslation } from 'react-i18next';
import LabelField from '../../form/LabelField';
import ScrollToFirstError from '../../route/ScrollToFirstError';

const UserForm = () => {
    const history = useHistory();
    const { setUsername, setFirstName, setLastName, userExistsByEmailPhone, setEmail, setPhone } = useContext(AccountContext);
    const { dataTypes } = useContext(DataTypeContext);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const { t } = useTranslation();
    const { questionPhrasing, states } = dataTypes;
    const [isLoading, setLoading] = useState(false);

    const schema = Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string()
            .email()
            .test('', 'Email or Phone is required', function (value) {
                const { phone } = this.parent;
                if (!phone) return value != null;
                return true;
            }),
        phone: Yup.string()
            .test('', 'Email or Phone is required', function (value) {
                const { email } = this.parent;
                if (!email) return value != null;
                return true;
            })
            .test('', 'Phone must be of 10 digits', function (value) {
                if (value !== undefined) {
                    const onlyNumbers = value.replace('+1', '').replace(/\D+/g, '');
                    if (onlyNumbers.length > 0) return onlyNumbers.length === 10;
                }
                return true;
            }),
        state: Yup.string().label(t('address.state')).required(),
    });

    const handleFormSubmit = async (values) => {
        if (!isLoading) {
            setLoading(true);
            const email = values.email || '';
            let phone = values.phone || '';
            phone = phone.replace(/[^0-9+]/g, '');
            const { firstName, lastName } = values;
            const cognitoUserResult = await userExistsByEmailPhone(email, phone);
            const checkUserExists = cognitoUserResult.userExists;
            const uname = cognitoUserResult.username;

            setFirstName(firstName);
            setLastName(lastName);
            setUsername(uname);
            setEmail(email);
            setPhone(phone.replace('+1', ''));

            if (checkUserExists) {
                setLoading(false);
                setShowLoginModal(true);
            } else {
                history.push('/create-account');
            }
        }
    };

    return (
        <>
            <LoginModal show={showLoginModal} onHide={() => setShowLoginModal(false)} />
            <Formik validationSchema={schema} onSubmit={handleFormSubmit} initialValues={{}}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="firstName">
                            <LabelField
                                label={questionPhrasing.firstName || t('personal.firstName')}
                                isRequired={isRequiredField(schema, 'firstName')}
                            />
                            <Form.Control
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                onChange={handleChange}
                                isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="lastName">
                            <LabelField
                                label={questionPhrasing.lastName || t('personal.lastName')}
                                isRequired={isRequiredField(schema, 'lastName')}
                            />
                            <Form.Control
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                onChange={handleChange}
                                isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="email">
                            <LabelField
                                label={questionPhrasing.emailAndPhone || t('personal.emailAndPhone')}
                                isRequired={true}
                            />
                            <small className="text-secondary" style={{ fontSize: '80%' }}>
                                (At Least One Required)
                            </small>
                            <Form.Control
                                type="text"
                                name="email"
                                placeholder="Email"
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                            />
                            <br />
                            <MaskedInput
                                className={errors.phone && touched.phone ? 'form-control is-invalid' : 'form-control'}
                                type="text"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                showMask={true}
                                mask={[
                                    '+',
                                    '1',
                                    ' ',
                                    '(',
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ')',
                                    ' ',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                ]}
                            />
                            {values.email && values.phone && (
                                <p>
                                    Your Login ID is <b>{values.email}</b>
                                </p>
                            )}
                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="state">
                            <LabelField
                                label={questionPhrasing.state || t('address.state')}
                                isRequired={isRequiredField(schema, 'state')}
                            />
                            <SelectField
                                as="select"
                                name="state"
                                value={values.state}
                                isInvalid={!!errors.state}
                                options={states}
                                includeEmpty={true}
                            />
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="text-center">
                            <Button variant="info" type="submit" disabled={isLoading} id="nextbutton">
                                {isLoading ? 'Loading..' : 'Next'}
                            </Button>
                        </Form.Group>
                        <ScrollToFirstError />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UserForm;
