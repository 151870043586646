import React, { useContext, useState } from 'react';
import { Form, Button, Row, Col, Badge } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import DatePickerField from '../../form/DatePickerField';
import RequiredAsterisk, { isRequiredField } from '../../form/RequiredAsterisk';
import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { COGNITO_ATTRIBUTE, API } from '../../Constants';
import LabelField from '../../form/LabelField';
import ScrollToFirstError from '../../route/ScrollToFirstError';
import { formatDate } from '../../Utils';

const schema = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
            'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character like (@#$&*!?)'
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match")
        .required('Confirm Password is required'),
    dob: Yup.date().max(new Date()).required('Date of Birth is required'),
    consentInfo: Yup.boolean()
        .required('Consent to Share Information is required.')
        .oneOf([true], 'Consent to Share Information is required.'),
    consentContactEmail: Yup.boolean(),
    consentContactSMS: Yup.boolean(),
});

const CreateAccountForm = () => {
    const {
        username,
        firstName,
        lastName,
        email,
        phone,
        setClientProfileId,
        signUp,
        setClientId,
        setLink2FeedClientId,
        setConsentInfoSigned,
        setConsentContactSMSSigned,
        setConsentContactEmailSigned,
        setConsentContactVoiceSigned,
        setLoginMethod,
    } = useContext(AccountContext);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { t } = useTranslation();
    const questionPhrasing = dataTypes.questionPhrasing;
    const [isLoading, setLoading] = useState(false);
    const enabledFields = dataTypes.enabledFields || [];

    return (
        <>
            {username && username !== '' ? (
                <Formik
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        if (!isLoading) {
                            setLoading(true);
                            const today = formatDate(new Date());
                            const { password, dob } = values;
                            const customAttributes = [
                                { name: COGNITO_ATTRIBUTE.FIRST_NAME, value: firstName },
                                { name: COGNITO_ATTRIBUTE.LAST_NAME, value: lastName },
                                {
                                    name: COGNITO_ATTRIBUTE.EMAIL_CONSENT_DATE,
                                    value: values.consentContactEmail ? today : '',
                                },
                                {
                                    name: COGNITO_ATTRIBUTE.SMS_CONSENT_DATE,
                                    value: values.consentContactSMS ? today : '',
                                },
                                {
                                    name: COGNITO_ATTRIBUTE.VOICE_CONSENT_DATE,
                                    value: values.consentContactVoice ? today : '',
                                },
                                { name: COGNITO_ATTRIBUTE.INFO_CONSENT_DATE, value: values.consentInfo ? today : '' },
                            ];

                            if (email) {
                                customAttributes.push({ name: COGNITO_ATTRIBUTE.EMAIL, value: email });
                                customAttributes.push({ name: COGNITO_ATTRIBUTE.LOGIN_METHOD, value: 'email' });
                                setLoginMethod('email');
                            }

                            if (phone) {
                                customAttributes.push({ name: COGNITO_ATTRIBUTE.PHONE, value: phone ? '+1' + phone : '' });
                                if (!email) {
                                    customAttributes.push({ name: COGNITO_ATTRIBUTE.LOGIN_METHOD, value: 'phone' });
                                    setLoginMethod('phone');
                                }
                            }

                            setConsentInfoSigned(today);

                            if (values.consentContactSMS) {
                                setConsentContactSMSSigned(today);
                            }

                            if (values.consentContactEmail) {
                                setConsentContactEmailSigned(today);
                            }

                            if (values.consentContactVoice) {
                                setConsentContactVoiceSigned(today);
                            }
                            
                            const signUpResponse = await signUp(username, password, customAttributes);
                            if (signUpResponse.userConfirmed) {
                                const token = await executeRecaptcha('password');
                                const payload = {
                                    token: token,
                                    firstName: firstName,
                                    lastName: lastName,
                                    dob: formatDate(dob),
                                };
                                await fetch(API.FIND_CLIENT_URL, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(payload),
                                })
                                    .then((response) =>
                                        response.json().then(async (data) => {
                                            if (response.ok) {
                                                setClientProfileId(data.clientProfileId);

                                                if (data.link2feedId !== undefined) {
                                                    setClientId(data.link2feedId);
                                                    setLink2FeedClientId((data.link2feedId || '').toString());
                                                    history.push('/thank-you');
                                                } else {
                                                    await fetch(API.FIND_CLIENT_URL + '/' + data.clientProfileId)
                                                        .then((response) => response.json())
                                                        .then((data) => {
                                                            history.push('/intake');
                                                        });
                                                }
                                            } else if (data.message !== undefined || data.error !== undefined) {
                                                alert(data.message || data.error);
                                            }
                                        })
                                    )
                                    .catch((error) => {
                                        alert('/find exception');
                                    });
                            } else {
                                alert('registration fail');
                            }
                        }
                    }}
                    initialValues={{}}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                        <Row>
                            <Col md={6} sm={12} className="mx-auto">
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="text-center">
                                        <Badge variant="dark">Page 1/2</Badge>
                                    </div>
                                    <h4 className="text-center">Create a New Account</h4>

                                    <Form.Group controlId="password">
                                        <LabelField
                                            label={questionPhrasing.password1 || t('account.password')}
                                            isRequired={isRequiredField(schema, 'password')}
                                        />
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="confirmPassword">
                                        <LabelField
                                            label={questionPhrasing.password2 || t('account.confirmPassword')}
                                            isRequired={isRequiredField(schema, 'confirmPassword')}
                                        />
                                        <Form.Control
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            onChange={handleChange}
                                            isInvalid={!!errors.confirmPassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="dob">
                                        <LabelField
                                            label={questionPhrasing.dob || t('personal.dob')}
                                            isRequired={isRequiredField(schema, 'dob')}
                                        />
                                        <DatePickerField
                                            className={!!errors.dob ? 'form-control is-invalid' : 'form-control'}
                                            id="dob"
                                            name="dob"
                                            popperClassName="d-none" // remove this to show datepicker
                                            placeholderText={dataTypes.dateFormat.toUpperCase()}
                                            dateFormat={dataTypes.dateFormat}
                                            maxDate={new Date()}
                                            showYearDropdown
                                            dateFormatCalendar="MMMM"
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="consentInfo">
                                        <Form.Label>Consent to Share Information</Form.Label>
                                        <p dangerouslySetInnerHTML={{ __html: dataTypes.informationDisclaimer }}></p>
                                        <Form.Check name="consentInfo" feedback={errors.consentInfo} id="consentInfo">
                                            <Form.Check.Input
                                                type="checkbox"
                                                isInvalid={!!errors.consentInfo}
                                                onChange={handleChange}
                                                value={false}
                                            />
                                            <Form.Check.Label>
                                                Consent to Share Information
                                                <RequiredAsterisk schema={schema} name="consentInfo" />
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.consentInfo}
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Form.Group>

                                    <Form.Group controlId="consentContact">
                                        <Form.Label>
                                            Consent to Contact
                                            <RequiredAsterisk schema={schema} name="consentContact" />
                                        </Form.Label>
                                        <p dangerouslySetInnerHTML={{ __html: dataTypes.consentDisclaimer }}></p>
                                        { enabledFields.includes('emailConsent') && (
                                        <Form.Check
                                            name="consentContactEmail"
                                            feedback={errors.consentContactEmail}
                                            id="consentContactEmail"
                                        >
                                            <Form.Check.Input
                                                type="checkbox"
                                                isInvalid={!!errors.consentContactEmail}
                                                onChange={handleChange}
                                                value={false}
                                            />
                                            <Form.Check.Label>Consent to Contact via Email</Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.consentContactEmail}
                                            </Form.Control.Feedback>
                                            {values?.consentContactEmail && !email && (
                                                <p className="fontsize">{t('account.consentContactEmailWarning')}</p>
                                            )}
                                        </Form.Check>
                                        )}
                                        { enabledFields.includes('smsConsent') && (
                                        <Form.Check
                                            name="consentContactSMS"
                                            feedback={errors.consentContactSMS}
                                            id="consentContactSMS"
                                        >
                                            <Form.Check.Input
                                                type="checkbox"
                                                isInvalid={!!errors.consentContactSMS}
                                                onChange={handleChange}
                                                value={false}
                                            />
                                            <Form.Check.Label>
                                                Consent to Contact via Text Message (SMS)
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.consentContactSMS}
                                            </Form.Control.Feedback>
                                            {values?.consentContactSMS && !phone && (
                                                <p className="fontsize">{t('account.consentContactSMSWarning')}</p>
                                            )}
                                        </Form.Check>
                                        )}
                                        { enabledFields.includes('voiceConsent') && (
                                        <Form.Check
                                            name="consentContactVoice"
                                            feedback={errors.consentContactVoice}
                                            id="consentContactVoice"
                                        >
                                            <Form.Check.Input
                                                type="checkbox"
                                                isInvalid={!!errors.consentContactVoice}
                                                onChange={handleChange}
                                                value={false}
                                            />
                                            <Form.Check.Label>
                                                {t('account.consent.toContactVoice')}
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.consentContactVoice}
                                            </Form.Control.Feedback>
                                            {values?.consentContactVoice && !phone && (
                                                <p className="fontsize">{t('account.consentContactSMSWarning')}</p>
                                            )}
                                        </Form.Check>
                                        )}
                                    </Form.Group>
                                    {isDeliveryEnabled && (
                                        <p className="fontsize">
                                            The organization's delivery system uses text (SMS) or email. Please opt in
                                            if you would like delivery confirmations and notifications.
                                        </p>
                                    )}
                                    <Form.Group className="text-center">
                                        <Button variant="info" type="submit" disabled={isLoading} id="nextButton">
                                            {isLoading ? 'Loading..' : 'Next'}
                                        </Button>
                                    </Form.Group>
                                    <ScrollToFirstError />
                                </Form>
                            </Col>
                        </Row>
                    )}
                </Formik>
            ) : (
                <Redirect to={{ pathname: '/' }} />
            )}
        </>
    );
};

export default CreateAccountForm;
