import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Form, Col } from 'react-bootstrap';
import { isRequiredField } from "./RequiredAsterisk";
import LabelField from "./LabelField";

const CheckboxesField = ({ ...props }) => {
    const { errors, setFieldValue } = useFormikContext();
    const { name, schema, types, label, value, onlyOneWhen } = props;
    let [checkedValues, setCheckedValues] = useState(value || []);
    const disabledValues = onlyOneWhen || ['undisclosed', 'none', 'not_applicable', 'declined_to_answer', 'do_not_know'];

    return (
        <Form.Group controlId={name}>
            <LabelField 
                label={label} 
                isRequired={isRequiredField(schema, name)}
            />
            <div className="row" key="inline-checkbox" id={name} tabIndex="0" >
                {
                    types.map((t) => (
                        <Col sm="6" key={`${name}-${t.name}`}>
                            <Form.Check
                                inline label={t.label} type="checkbox" name={name}
                                id={`inline-${name}-${t.name}`}
                                value={t.name}
                                checked={checkedValues.includes(t.name)}
                                disabled={disabledValues.some(v => checkedValues.includes(v)) && t.name !== checkedValues[0]}
                                onChange={ (e) => {
                                    const targetValue = e.target.value;
                                    const isChecked = e.target.checked;

                                    if (isChecked && !checkedValues.includes(targetValue)) {
                                        checkedValues.push(targetValue);
                                    }
                                    else if (!isChecked && checkedValues.includes(targetValue)) {
                                        checkedValues = checkedValues.filter(v => v !== targetValue)
                                    }

                                    if (disabledValues.includes(targetValue) && isChecked)
                                        checkedValues = checkedValues.filter(v => v === targetValue)

                                    setCheckedValues(checkedValues);
                                    setFieldValue(name, checkedValues);
                                  }
                                }
                                isInvalid={!!errors[name]}
                            />
                        </Col>
                    ))
                }
            </div>
        </Form.Group>
    );
};

export default CheckboxesField;
