import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { APPOINTMENTS_DATE_FORMAT } from '../../Constants';
import BSButton from '../../form/BSButton';

const ConfirmAddress = ({ addressObject, confirmAddressHandler }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const existingAppointment = location && location.state !== undefined && location.state.existingAppointment;

    if (!addressObject) {
        return (
            <div className="text-center">
                <Spinner animation="border" variant="info" />
            </div>
        );
    }

    return (
        <Row>
            <Col md={6} sm={12} className="mx-auto text-center">
                {existingAppointment ? (
                    <h5 className="mb-4">
                        {t('confirmAddress.heading') + ' '}
                        <b>
                            <u>on {moment(existingAppointment.datetime).format(APPOINTMENTS_DATE_FORMAT)}</u>
                        </b>
                        :
                    </h5>
                ) : (
                    <h5 className="mb-4">{t('confirmAddress.heading')}:</h5>
                )}
                <div className="text-left mb-4">
                    <p className="mb-2">Address on file:</p>
                    <p className="mb-2">{addressObject.addressLine1}</p>
                    {addressObject.addressLine2 && <p className="mb-2">{addressObject.addressLine2}</p>}
                    <p>
                        {addressObject.city}, {addressObject.state} {addressObject.zip}
                    </p>
                </div>
                <Col lg={6} md={8} sm={12} className="d-flex flex-column mx-auto text-center">
                    <BSButton onClick={confirmAddressHandler} variant="primary" className="mb-2">
                        {t('confirmAddress.useThisAddress')}
                    </BSButton>
                    <BSButton as={Link} to="/update-address" variant="primary">
                        {t('confirmAddress.updateMyAddress')}
                    </BSButton>
                </Col>
            </Col>
        </Row>
    );
};

export default ConfirmAddress;
