import React from 'react';
import { getIn } from 'formik';

export const isRequiredField = (validationSchema, name) => {
    const field = getIn(validationSchema.describe().fields, name);
    const tests = field !== undefined ? field.tests : null;
    return field !== undefined && tests !== undefined ? !!tests.find((test) => test['name'] === 'required') : false;
};

function RequiredAsterisk(props) {
    return (
        <React.Fragment>
            {isRequiredField(props.schema, props.name) && <span className="required-attribute text-info">&nbsp;*</span>}
        </React.Fragment>
    );
}

export default RequiredAsterisk;
