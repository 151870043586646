import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import SelectField from '../form/SelectField';
import { DataTypeContext } from '../DataTypes';
import { useTranslation } from 'react-i18next';
import LabelField from '../form/LabelField';
import SmartyStreetsAutocomplete from './autocomplete/SmartyStreetsAutocomplete';
import PeliasAutocomplete from './autocomplete/PeliasAutocomplete';
import ScrollToFirstError from '../route/ScrollToFirstError';
import MaskedInput from 'react-text-mask';
import { COUNTRY } from '../Constants';

const AddressForm = (props) => {
    const { values, errors, handleChange, setFieldValue, hideUndisclosedCheckbox = false } = props;
    const { dataTypes } = useContext(DataTypeContext);
    let [noFixedAddress, setNoFixedAddress] = useState(values.noFixedAddress || false);
    let [addressDeclinedToAnswer, setAddressDeclinedToAnswer] = useState(values.addressDeclinedToAnswer || false);
    const enabledFields = dataTypes.enabledFields || [];
    const { t } = useTranslation();
    const questionPhrasing = dataTypes.questionPhrasing;
    let mask = null;
    if (COUNTRY === 'CAN')
        mask = [
            /^[ABCEGHJ-NPRSTVXY abceghj-nprstvxy]+$/,
            /^[0-9]+$/,
            /^[A-Za-z]+$/,
            ' ',
            /^[0-9]+$/,
            /^[A-Za-z]+$/,
            /^[0-9]+$/,
        ];
    else if (COUNTRY === 'USA') mask = [/^[0-9]+$/, /^[0-9]+$/, /^[0-9]+$/, /^[0-9]+$/, /^[0-9]+$/];

    return (
        <>
            <Form.Group controlId="addressLine1">
                <LabelField label={questionPhrasing.address || t('address.addressLine1')} isRequired={true} />
                {dataTypes.useUSPSAutocomplete && (
                    <SmartyStreetsAutocomplete
                        inputProps={{
                            name: 'addressLine1',
                            id: 'addressLine1', // id for the custom component
                            placeholder: t('address.addressLine1'),
                            disabled: noFixedAddress || addressDeclinedToAnswer,
                            value: values.addressLine1,
                        }}
                    />
                )}
                {dataTypes.useAutocomplete && !dataTypes.useUSPSAutocomplete && (
                    <PeliasAutocomplete
                        inputProps={{
                            name: 'addressLine1',
                            id: 'addressLine1', //id for the custom component
                            placeholder: t('address.addressLine1'),
                            disabled: noFixedAddress || addressDeclinedToAnswer,
                            value: values.addressLine1,
                        }}
                    />
                )}
                {!dataTypes.useAutocomplete && !dataTypes.useUSPSAutocomplete && (
                    <Form.Control
                        type="text"
                        name="addressLine1"
                        value={values.addressLine1}
                        placeholder={t('address.addressLine1')}
                        onChange={handleChange}
                        isInvalid={!!errors.addressLine1}
                        disabled={noFixedAddress || addressDeclinedToAnswer}
                    />
                )}
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.addressLine1}
                </Form.Control.Feedback>
            </Form.Group>

            {enabledFields.includes('addressLine2') && (
                <Form.Group controlId="addressLine2">
                    <LabelField label={questionPhrasing.address2 || t('address.addressLine2')} isRequired={false} />
                    <Form.Control
                        type="text"
                        name="addressLine2"
                        value={values.addressLine2}
                        placeholder={t('address.addressLine2')}
                        onChange={handleChange}
                        isInvalid={!!errors.addressLine2}
                        disabled={noFixedAddress || addressDeclinedToAnswer}
                    />
                    <Form.Control.Feedback type="invalid">{errors.addressLine2}</Form.Control.Feedback>
                </Form.Group>
            )}

            <Form.Control
                type="hidden"
                name="latitude"
                value={values.latitude}
            />

            <Form.Control
                type="hidden"
                name="longitude"
                value={values.longitude}
            />

            <Form.Group controlId="city">
                <LabelField label={questionPhrasing.city || t('address.city')} isRequired={true} />
                <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    placeholder={t('address.city')}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    disabled={noFixedAddress || addressDeclinedToAnswer}
                />
                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
            </Form.Group>

            {enabledFields.includes('county') && (
                <Form.Group controlId="county">
                    <LabelField label={questionPhrasing.county || t('address.county')} isRequired={true} />
                    <Form.Control
                        type="text"
                        name="county"
                        value={values.county}
                        placeholder={t('address.county')}
                        onChange={handleChange}
                        isInvalid={!!errors.county}
                        disabled={noFixedAddress || addressDeclinedToAnswer}
                    />
                    <Form.Control.Feedback type="invalid">{errors.county}</Form.Control.Feedback>
                </Form.Group>
            )}

            <Form.Group controlId="state">
                <LabelField label={questionPhrasing.state || t('address.state')} isRequired={true} />
                <SelectField
                    as="select"
                    name="state"
                    isInvalid={!!errors.state}
                    options={dataTypes.states}
                    includeEmpty={true}
                    disabled={noFixedAddress || addressDeclinedToAnswer}
                />
                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="zip">
                <LabelField label={questionPhrasing.zipCode || t('address.zipcode')} isRequired={true} />
                <MaskedInput
                    className={errors.zip ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="zip"
                    id="zip"
                    value={values.zip}
                    onChange={handleChange}
                    disabled={noFixedAddress || addressDeclinedToAnswer}
                    showMask={true}
                    mask={mask}
                />
                <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
            </Form.Group>

            {!hideUndisclosedCheckbox && (
              <>
                <Form.Group controlId="noFixedAddress">
                    <Form.Check
                        inline 
                        label={<Form.Label dangerouslySetInnerHTML={{ __html: questionPhrasing.noFixedAddress || t('address.noFixedAddress') }}></Form.Label>}
                        type="checkbox" 
                        name="noFixedAddress"
                        checked={values.noFixedAddress}
                        value={values.noFixedAddress}
                        disabled={addressDeclinedToAnswer}
                        onChange={(e) => { 
                            setNoFixedAddress(e.target.checked);
                            setFieldValue('noFixedAddress', e.target.checked);
                          }
                        }
                    />
                </Form.Group>

                <Form.Group controlId="addressDeclinedToAnswer">
                    <Form.Check
                        inline 
                        label={<Form.Label dangerouslySetInnerHTML={{ __html: questionPhrasing.addressDeclinedToAnswer || t('address.addressDeclinedToAnswer') }}></Form.Label>}
                        type="checkbox" 
                        name="addressDeclinedToAnswer"
                        checked={values.addressDeclinedToAnswer}
                        value={values.addressDeclinedToAnswer}
                        disabled={noFixedAddress}
                        onChange={(e) => { 
                            handleChange(e);
                            setAddressDeclinedToAnswer(e.target.checked);
                          }
                        }
                    />
                </Form.Group>
              </>
            )}
            <ScrollToFirstError/>
        </>
    );
};

export default AddressForm;
