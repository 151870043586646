import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { DataTypeContext } from '../DataTypes';
import { AccountContext } from '../Accounts';
import LabelField from '../form/LabelField';
import { API } from '../Constants';
import { isRequiredField } from '../form/RequiredAsterisk';

const DoYouHaveId = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dataTypes } = useContext(DataTypeContext);
    const { setClientId, setLink2FeedClientId, logout } = useContext(AccountContext);
    const [response, setResponse] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const {
        questionPhrasing: { doYouHaveAnIdTitle, doYouHaveAnIdText, doYouHaveAnIdPrompt },
        networkId,
    } = dataTypes;

    const schema = Yup.object({
        link2feedId: Yup.string().required().label(doYouHaveAnIdPrompt.replace(/(<([^>]+)>)/gi, "")),
    });

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            const body = {
                id: values.link2feedId,
                network_id: networkId,
            };

            const url = new URL(API.CNCT_ADMIN_FIND_CLIENT_URL);
            url.search = new URLSearchParams(body).toString();

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(response.status === 404 ? t('core.error.notFound') : t('core.error.unknown'));
            }


            const findResponse = await response.json();

            if (parseInt(findResponse.networkId) !== networkId) {
                throw new Error(t('core.error.notFound'));
            }

            if (findResponse.link2feedId !== undefined) {
                if (findResponse.cognitoId) {
                    history.push({
                        pathname: '/account-exists',
                        state: { definiteMatch: true },
                    });
                } else {
                    setClientId(findResponse.link2feedId);
                    setLink2FeedClientId((findResponse.link2feedId || '').toString());
                    history.push('/create-account');
                }
            }
        } catch (err) {
            setLoading(false);
            if (err.message === 'Not Found') {
                setResponse(t('account.id.notFound'));
            } else if (err.message) {
                setResponse(err.message);
            }
        }
    };

    // eslint-disable-next-line
    useEffect(() => logout(), []);

    return (
        <Container>
            <Row>
                <Col md={6} sm={12} className="mx-auto ">
                    <h4 className="text-center" dangerouslySetInnerHTML={{ __html: doYouHaveAnIdTitle }}></h4>
                    <p className="mb-4 text-center" dangerouslySetInnerHTML={{ __html: doYouHaveAnIdText }}></p>
                    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ link2feed: '' }}>
                        {({ handleSubmit, handleChange, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="link2feedId">
                                    <LabelField
                                        label={doYouHaveAnIdPrompt}
                                        isRequired={isRequiredField(schema, 'link2feedId')}
                                    />
                                    <Form.Control
                                        type="text"
                                        name="link2feedId"
                                        isInvalid={!!errors.link2feedId}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.link2feedId}</Form.Control.Feedback>
                                </Form.Group>
                                {response !== null && <p className="text-danger">{response}</p>}
                                <Form.Group className="d-flex justify-content-end">
                                    <Button as={Link} className="mr-2" variant="outline-dark" to="/create-account" onClick={() => { setClientId(''); setLink2FeedClientId(''); }}>
                                        {t('account.id.doNotHaveButton')}
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isLoading}>
                                        {t(`core.action.${isLoading ? 'loading' : 'submit'}`)}
                                    </Button>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default DoYouHaveId;
