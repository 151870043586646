import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import RequiredAsterisk, { isRequiredField } from '../../form/RequiredAsterisk';
import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { API, COGNITO_ATTRIBUTE } from '../../Constants';
import LabelField from '../../form/LabelField';
import ScrollToFirstError from '../../route/ScrollToFirstError';
import { formatDate } from '../../Utils';

const CreateAccountFormStep2 = () => {
    const { username, firstName, lastName, email, phone, clientId, setClientProfileId, signUp, setConsentInfoSigned, setConsentContactEmailSigned, setConsentContactSMSSigned, setConsentContactVoiceSigned, setLoginMethod } =
        useContext(AccountContext);
    const { dataTypes, isDeliveryEnabled } = useContext(DataTypeContext);
    const history = useHistory();
    const { t } = useTranslation();
    const { questionPhrasing } = dataTypes;
    const [isLoading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const enabledFields = dataTypes.enabledFields || [];

    const schema = Yup.object({
        password: Yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
                'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character like (@#$&*!?)'
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords don't match")
            .required('Confirm Password is required'),

        consentInfo: Yup.boolean()
            .required('Consent to Share Information is required.')
            .oneOf([true], 'Consent to Share Information is required.'),
        consentContactEmail: Yup.boolean(),
        consentContactSMS: Yup.boolean(),
        consentContactVoice: Yup.boolean(),
    });

    const onSubmit = async (values) => {
        try {
            if (!isLoading) {
                setLoading(true);
                const today = formatDate(new Date());
                const { password, dob } = values;
                const customAttributes = [
                    { name: COGNITO_ATTRIBUTE.FIRST_NAME, value: firstName },
                    { name: COGNITO_ATTRIBUTE.LAST_NAME, value: lastName },
                    {
                        name: COGNITO_ATTRIBUTE.EMAIL_CONSENT_DATE,
                        value: values.consentContactEmail ? today : '',
                    },
                    {
                        name: COGNITO_ATTRIBUTE.SMS_CONSENT_DATE,
                        value: values.consentContactSMS ? today : '',
                    },
                    {
                        name: COGNITO_ATTRIBUTE.VOICE_CONSENT_DATE,
                        value: values.consentContactVoice ? today : '',
                    },
                    { name: COGNITO_ATTRIBUTE.INFO_CONSENT_DATE, value: values.consentInfo ? today : '' },
                ];

                if (clientId) {
                    customAttributes.push({ name: COGNITO_ATTRIBUTE.CLIENT_ID, value: clientId });
                }

                if (email) {
                    customAttributes.push({ name: COGNITO_ATTRIBUTE.EMAIL, value: email });
                    customAttributes.push({ name: COGNITO_ATTRIBUTE.LOGIN_METHOD, value: 'email' });
                    setLoginMethod('email');
                }

                if (phone) {
                    customAttributes.push({ name: COGNITO_ATTRIBUTE.PHONE, value: phone ? '+1' + phone : '' });
                    if (!email) {
                        customAttributes.push({ name: COGNITO_ATTRIBUTE.LOGIN_METHOD, value: 'phone' });
                        setLoginMethod('phone');
                    }
                }

                const signUpResponse = await signUp(username, password, customAttributes);
                if (signUpResponse.userConfirmed) {
                    const token = await executeRecaptcha('password');
                    const payload = {
                        token: token,
                        firstName: firstName,
                        lastName: lastName,
                        dob: formatDate(dob),
                    };
                    const response = await fetch(API.FIND_CLIENT_URL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    const data = await response.json();

                    if (response.ok) {
                        setClientProfileId(data.clientProfileId);
                        setConsentInfoSigned(today)

                        if (values.consentContactSMS) {
                            setConsentContactSMSSigned(today)
                        }

                        if (values.consentContactEmail) {
                            setConsentContactEmailSigned(today)
                        }

                        if (values.consentContactVoice) {
                            setConsentContactVoiceSigned(today)
                        }

                        if (clientId) {
                            history.push({ pathname: '/intake', state: { edit: true } });
                        } else {
                            history.push('/intake');
                        }
                    } else if (data.message !== undefined || data.error !== undefined) {
                        throw new Error(data.message || data.error);
                    }
                } else {
                    throw new Error('Registration fail');
                }
            }
        } catch (err) {
            setLoading(false);
            if (err.code && err.message) {
                setResponse(err.message);
            }
        }
    };

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{}}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <h4 className="text-center">{t('personal.createAccount')} - 2/2</h4>
                    <p className="text-center"dangerouslySetInnerHTML={{ __html: questionPhrasing?.createAccountPage2 }}></p>
                    <Form.Group controlId="password">
                        <LabelField
                            label={questionPhrasing.password1 || t('account.password')}
                            isRequired={isRequiredField(schema, 'password')}
                        />
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                        <LabelField
                            label={questionPhrasing.password2 || t('account.confirmPassword')}
                            isRequired={isRequiredField(schema, 'confirmPassword')}
                        />
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            isInvalid={!!errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="consentInfo">
                        <Form.Label>{t('account.consent.shareInformationLabel')}</Form.Label>
                        <p dangerouslySetInnerHTML={{ __html: dataTypes.informationDisclaimer }}></p>
                        <Form.Check name="consentInfo" feedback={errors.consentInfo} id="consentInfo">
                            <Form.Check.Input
                                type="checkbox"
                                isInvalid={!!errors.consentInfo}
                                onChange={handleChange}
                                value={false}
                            />
                            <Form.Check.Label>
                                {t('account.consent.shareInformationLabel')}
                                <RequiredAsterisk schema={schema} name="consentInfo" />
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">{errors.consentInfo}</Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>

                    <Form.Group controlId="consentContact">
                        <Form.Label>
                            {t('account.consent.toContactLabel')}
                            <RequiredAsterisk schema={schema} name="consentContact" />
                        </Form.Label>
                        <p dangerouslySetInnerHTML={{ __html: dataTypes.consentDisclaimer }}></p>
                        { enabledFields.includes('emailConsent') && (
                        <Form.Check
                            name="consentContactEmail"
                            feedback={errors.consentContactEmail}
                            id="consentContactEmail"
                        >
                            <Form.Check.Input
                                type="checkbox"
                                isInvalid={!!errors.consentContactEmail}
                                onChange={handleChange}
                                value={false}
                            />
                            <Form.Check.Label>{t('account.consent.toContactEmail')}</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">{errors.consentContactEmail}</Form.Control.Feedback>
                            {values?.consentContactEmail && !email && (
                                <p className="fontsize">{t('account.consentContactEmailWarning')}</p>
                            )}
                        </Form.Check>
                        )}
                        { enabledFields.includes('smsConsent') && (
                        <Form.Check name="consentContactSMS" feedback={errors.consentContactSMS} id="consentContactSMS">
                            <Form.Check.Input
                                type="checkbox"
                                isInvalid={!!errors.consentContactSMS}
                                onChange={handleChange}
                                value={false}
                            />
                            <Form.Check.Label>{t('account.consent.toContactSMS')}</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">{errors.consentContactSMS}</Form.Control.Feedback>
                            {values?.consentContactSMS && !phone && (
                                <p className="fontsize">{t('account.consentContactSMSWarning')}</p>
                            )}
                        </Form.Check>
                        )}
                        { enabledFields.includes('voiceConsent') && (
                        <Form.Check name="consentContactVoice" feedback={errors.consentContactVoice} id="consentContactVoice">
                            <Form.Check.Input
                                type="checkbox"
                                isInvalid={!!errors.consentContactVoice}
                                onChange={handleChange}
                                value={false}
                            />
                            <Form.Check.Label>{t('account.consent.toContactVoice')}</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">{errors.consentContactVoice}</Form.Control.Feedback>
                            {values?.consentContactVoice && !phone && (
                                <p className="fontsize">{t('account.consentContactSMSWarning')}</p>
                            )}
                        </Form.Check>
                        )}
                    </Form.Group>
                    {isDeliveryEnabled && <p className="fontsize">{t('delivery.consentContactWarning')}</p>}

                    {response !== null && <p className="text-danger">{response}</p>}
                    <Form.Group className="d-flex justify-content-end">
                        <Button as={Link} className="mr-2" variant="outline-dark" to="/">
                            {t('core.action.cancel')}
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {t(`core.action.${isLoading ? 'loading' : 'submit'}`)}
                        </Button>
                    </Form.Group>
                    <ScrollToFirstError />
                </Form>
            )}
        </Formik>
    );
};

export default CreateAccountFormStep2;
