import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';

const TextField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <Form.Control
            {...field}
            {...props}
            onChange={(event) => {
                setFieldValue(field.name, event.target.value);
            }}
        />
    );
};

export default TextField;
